import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./Low.module.scss";
//import viruses from "../../../../../assets/viruses.svg";

export const Low = (props) => {
  useEffect(() => {
    document.title = "特定商取引法に基づく表記｜トリエルPCR検査";
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            特定商取引法に基づく表記
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.sectionContent}>
            <table>
              <tr>
                <th>販売業者名</th>
                <td>株式会社トリエル</td>
              </tr>
              <tr>
                <th>販売責任者</th>
                <td>武藤 博俊</td>
              </tr>
              <tr>
                <th>所在地</th>
                <td>
                  <a href="https://goo.gl/maps/YqnSEPxMUX1fJqco7" target="_blank" rel="noreferrer">
                    {" "}
                    〒370-2316 群馬県富岡市富岡1414-9
                  </a>
                </td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td>
                  <a href="tel:0274677061">0274-67-7061</a>
                </td>
              </tr>
              <tr>
                <th>価格</th>
                <td>商品の紹介ページに記載してある通りとします。</td>
              </tr>
              <tr>
                <th>商品代金以外の必要料金</th>
                <td>消費税</td>
              </tr>
              <tr>
                <th>
                  キャンセルについて
                </th>
                <td className={classes.longText}>
                  検査実施前日までにキャンセル連絡をいただいた場合には、原則、
                  <br className={`${classes.dn600}`} />
                  翌営業日に返金処理を行います。
                  <br />
                  ※検査当日のキャンセルの取り消しにつきましては、
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  返金いたしません。 
                </td>
              </tr>
            </table>
            <div className={`${classes.sectionTitle} ${classes.secondTitle}`}>
              ご利用案内
              {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
            </div>
            <table className={classes.secondTable}>
              <tr>
                <th rowspan="4">お支払方法</th>
                <td>・クレジットカード決済 Visa/Mastercard（SBPS）</td>
              </tr>
              <tr>
                <td>・クレジットカード決済 JCB/AMEX/DINERS</td>
              </tr>
              <tr>
                <td>・現金支払い</td>
              </tr>
              {/* <tr>
                <td>・ソフトバンクまとめて支払いB</td>
              </tr>
              <tr>
                <td>・ドコモ払い</td>
              </tr> */}
              <tr>
                {/* <td className={classes.longText}> ※各お支払い方法ともオンライン決済となります。</td> */}
                <td className={classes.longText}>
                  ※クレジットカード決済は、オンライン予約のみご利用いただけます。<br />
                  現地にてクレジットカード決済はご利用いただけません。<br/> 現金による支払いのみになります。
                </td>
              </tr>
            </table>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
