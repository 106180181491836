import React from "react";
import { NavLink } from "react-router-dom";
import classes from "./Header.module.scss";

export const Header = () => {
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <NavLink to={"/"}>
          <span className={classes.title}>
            トリエルPCR検査センターたかさき
          </span>
        </NavLink>
        <span className={classes.text}>高崎市登録第3号 登録衛生検査所</span>
      </div>
    </div>
  );
};
