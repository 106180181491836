import React, { useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import classes from "./HomePage.module.scss";
import { motion } from "framer-motion";
import { Context } from "../../../../context/context";
import clock from "../../../../assets/clock.svg";
import vase from "../../../../assets/vase.svg";
import pc from "../../../../assets/pc.svg";
import hospital from "../../../../assets/hospital.svg";
import emailRead from "../../../../assets/emailRead.svg";
import { ImCross } from "react-icons/im";
import aboutbutton from "../../../../assets/btns/aboutbutton.svg";
import calendar from "../../../../assets/btns/calendar.svg";
import feedback from "../../../../assets/btns/feedback.svg";
import faq from "../../../../assets/btns/faq.svg";
import concent from "../../../../assets/btns/concent.svg";
import rule from "../../../../assets/btns/rule.svg";
import low from "../../../../assets/btns/low.svg";
import startmail from "../../../../assets/btns/startmail.svg";
import closebtn from "../../../../assets/btns/closebtn.svg";
import poolbutton from "../../../../assets/btns/poolbutton.svg";
import $ from "jquery";

export const HomePage = (props) => {
  const { sendStartMail } = useContext(Context);
  const location = useLocation();
  useEffect(() => {
    document.title = "スピーディーに結果を得られる新型コロナウイルスPCR検査 | トリエルPCR検査センターたかさき";
    // eslint-disable-next-line
    //document.getElementById("inspection1").checked = true;
  }, []);
  const [showPopup, setShowPopup] = useState(false);
  const [thankYou, setthankYou] = useState(false);
  const [email, setEmail] = useState("");
  const [facility, setFacility] = useState(1);
  //const [inspectionMethod, setInspectionMethod] = useState([true, false]);
  const onSetFacility = (event) => {
    setFacility(event.target.value);
  };
  const onShowPopup = () => {
    if (!showPopup) {
      $("body").addClass("bodyOverflow");
    } else {
      $("body").removeClass("bodyOverflow");
    }
    setShowPopup(!showPopup);
    setthankYou(false);
    //setEmail("") - nujno li resetit'
  };
  const onSetEmail = (event) => {
    setEmail(event.target.value);
  };
  const onSubmit = (event) => {
    event.preventDefault();
    let coupon = location.pathname.split("/").reverse()[0];
    sendStartMail(email, facility, coupon);
    setthankYou(true);
  };
  const scrollTop = () => {
    $("html, body").scrollTop(0);
  };
  /*
  const checkInspection = (num) => {
    document.getElementById("inspection" + num).checked = true;
    if (num === 1) {
      setInspectionMethod([true, false]);
    } else {
      setInspectionMethod([false, true]);
    }
  }
  */
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <div className={classes.topSection}>
          <div className={classes.banner}>
            <div className={classes.title1}>新型コロナウイルス</div>
            <div className={classes.title2}>
              スピーディーに結果を得られる
              <br className={classes.db600} />
              検査センターを開設しました
            </div>
             {/*0509抗原 <h1 className={classes.title3}>PCR・抗原検査</h1> */}
            <h1 className={classes.title3}>PCR検査</h1>
          </div>
          <div className={classes.messege}>
            {/* <div className={classes.titleWrapper}>
              <div className={classes.line}></div>
              <h3 className={classes.title}>トリエルのPCR検査センターたかさき</h3>
              <br/>
              <h3 className={classes.title}>抗原検査の特徴</h3>
            </div>
            <div className={classes.topMessage}>安心・簡単・迅速</div>
            <div className={classes.messeges}>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                検査キットは、国が承認した「対外診断用医薬品」なので安心。
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                ドライブスルー方式なのでソーシャルディスタンスを守れます。
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                15分程度で判定できます。
              </div>
            </div> */}
            <div className={classes.titleWrapper}>
              <div className={classes.line}></div>
              <h3 className={classes.title}>検査はどんな方におすすめ?</h3>
              {/* <h3 className={classes.title}>こんな方におすすめ</h3> */}
            </div>
            <div className={classes.messeges}>
              {/*<div className={classes.message}>
                ・PCR検査結果の陰性証明書、
                <span className={classes.di414}>
                  <br />
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                診断書が必要な方
              </div>*/}
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                症状が無くても新型コロナウイルスの
                <br className={classes.di414} />
                感染が心配な方
                {/* 症状（発熱や咳など）がある方 */}
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                お仕事で不特定多数の人と接する機会の多い方
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                企業や団体で定期的に検査が必要な方
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                企業や団体の福利厚生として
                <br className={classes.di414} />
                従業員に検査を実施したい
              </div>
              <div className={classes.message}>
                <span className={classes.messageDot}>・</span>
                検査キットを買って、自分で検査するのは不安
              </div>
            </div>
            {/* <div className={classes.titleWrapper}>
              <div className={classes.line}></div>
              <h3 className={classes.title}>PCR検査と抗原検査の違いとは？</h3>
            </div>
            <div className={classes.messeges}>
              <div className={classes.message}>
                抗原検査はPCR検査よりも、感度が劣ります。
              </div>
              <div className={classes.message}>
                ※感度・・・新型コロナウイルスに感染している人で、検査で陽性となる割合
              </div>
              <br />
              <div className={classes.message}>
              PCR検査と抗原検査のメリット、デメリットをご理解いただき、状況に合った方法を選択して、検査をしていただくのが良いと思います。ご不明な点等ありましたら、遠慮なくお問い合わせください。
              </div>
            </div> */}
          </div>
        </div>
        <section className={`${classes.section} ${classes.about}`}>
          {/*0509抗原 <h2 className={classes.sectionTitle}>
            PCR・抗原検査<span>について</span>
          </h2> */}
          <h2 className={classes.sectionTitle}>
            PCR検査<span>について</span>
          </h2>
          <div className={classes.aboutContent}>
            <div className={classes.top}>
              <div className={classes.vaseWrapper}>
                <img className={classes.vase} src={vase} alt="vase" />
              </div>
              <div className={classes.textWrapper}>
                <div className={classes.title}>安心・簡単・迅速</div>
                <div className={classes.text}>
                  ドライブスルー方式なので
                  <br />
                  ソーシャルディスタンスを守れます。
                </div>
                <div className={classes.text}>
                  専用の容器に唾液を取るだけで
                  <br />
                  検体採取が簡単です。
                </div>
                <div className={classes.textTime}>
                  採取の所要時間は
                  <img className={classes.clock} src={clock} alt="clock" />約<span>10</span>
                  分。
                </div>
                {/*0509抗原 <div className={`${classes.text}`}>PCR検査は半日～1日、抗原検査は30分ほどで<br className={classes.br411}/>検査結果を回答いたします。</div> */}
                <div className={`${classes.text}`}>PCR検査は半日～1日ほどで<br className={classes.br411}/>検査結果を回答いたします。</div>
                {/*
                <div className={classes.text}>
                  ご希望の方には「陰性証明書」
                  <br className={classes.db414} />
                  「診断書」
                  <br className={classes.dn414} />
                  の発行も可能です(有料）。
                </div>*/}
              </div>
            </div>
            {/* <div className={classes.bottom}>
              <NavLink
                to={"/aboutTest"}
                onClick={() => {
                  scrollTop();
                }}
                className={classes.btnImgWrapper}
              >
                <img src={aboutbutton} alt="PCR検査について" />
              </NavLink>
            </div> */}
          </div>
        </section>
        {/*0509抗原 <section className={`${classes.section} ${classes.about}`}>
          <h2 className={classes.sectionTitle}>
            PCR検査と抗原検査の違いとは？
          </h2>
          <div className={classes.aboutContent}>
            <div className={classes.top}>
              <div className={classes.textWrapper2}>
                <div className={classes.text}>
                  感度や結果判定までの時間、料金に違いがあります。
                </div>
                <table>
                  <tr>
                    <th>検査方法</th>
                    <th>感度</th>
                    <th>結果判定まで</th>
                    <th>値段</th>
                  </tr>
                  <tr>
                    <td>PCR検査</td>
                    <td>とても高い</td>
                    <td>半日～１日</td>
                    <td>5,000円</td>
                  </tr>
                  <tr>
                    <td>抗原検査</td>
                    <td><div className={classes.txt}>PCR検査<br />より劣る</div></td>
                    <td>30分程度</td>
                    <td>3,000円</td>
                  </tr>
                </table>
                <div className={classes.text}>
                  PCR検査と抗原検査のメリット、デメリットをご理解いただき、状況に合った方法を選択して、検査をしていただくのが良いと思います。
                  <br />
                  ご不明な点等ありましたら、遠慮なくお問い合わせください。
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className={`${classes.section} ${classes.flow}`}>
          <h2 className={classes.sectionTitle}>
            検査<span>の</span>流れ
          </h2>
          <div className={classes.flowContent}>
            <div className={classes.flowMain}>
              <div className={classes.flowList}>
                <div>予 約</div>
                <div>→</div>
                <div>検 体 採 取</div>
                <div>→</div>
                <div>結 果</div>
              </div>
              <div className={classes.flowListItems}>
                <div className={classes.flowListItem}>
                  <div className={classes.pc}>
                    <img className={classes.pcImage} src={pc} alt="pc" />
                    {/* <div>
                      ①予約WEBサイトで、
                      <br />
                      検査日を決定。
                    </div>   */}
                  </div>
                  <div className={classes.pc}>
                    ①予約WEBサイトで、
                    <br />
                    検査日を決定。
                  </div>
                  {/*<img
                    className={classes.flowArrow}
                    src={flowArrow}
                    alt="flowArrow"
                  />
                  <div className={classes.handClick}>
                    <img
                      className={classes.handClickImg}
                      src={handClick}
                      alt="handClick"
                    />
                    まる2、まる3の変更を忘れない
                    <div>②検査の場所を選択。</div>
                  </div> */}
                </div>
                <div className={classes.flowListItem}>
                  <div className={classes.hospital}>
                    <div className={classes.hospitalText}>
                      ②来所時、
                      <br />
                      ドライブスルーで
                      <br />
                      唾液を採取。
                    </div>
                    <div className={classes.hospitalImgWrapper}>
                      <img className={classes.hospitalImg} src={hospital} alt="hospital" />
                    </div>
                  </div>
                  {/*
                  <div className={classes.home}>
                    <div className={classes.title}>「郵送」を選択した方</div>
                    <img className={classes.homeImg} src={home} alt="home" />
                    <div className={classes.homeText}>
                    検体採取キット到着後、
                      <br />
                      唾液を採取。
                      <br />
                      <span>規定の郵送方法</span>
                      で返送。
                    </div>
                    <div className={classes.homePostLink}>
                      <img
                        className={classes.handClick2}
                        src={handClick2}
                        alt="handClick2"
                      />
                      <NavLink
                        to={"/aboutPostMail"}
                        onClick={() => {
                          window.scrollTo(0, 0);
                        }}
                        className={classes.text}
                      >
                        規定<span>の</span>郵送方法
                        <span>について</span>
                        <br />
                        詳しく<span>は</span>こちら
                      </NavLink>
                    </div>
                  </div>*/}
                </div>
                <div className={`${classes.flowListItem}`}>
                  <div>
                    <img className={classes.emailRead} src={emailRead} alt="emailRead" />
                  </div>
                  <div className={classes.emailReadText}>
                    ③検査結果を
                    <br />
                    メールアドレスにて受信。
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.flowWornings}>
              <dir className={classes.worning}>
                <span>※</span>
                検査の30分前から食事・飲水・歯磨きは
                <br className={classes.db600} />
                控えてください。
              </dir>
              {/*0509抗原 <dir className={classes.worning}>
                <span>※</span>
                検査結果が出るまでPCR検査で半日～１日程度かかり、<br />抗原検査で当日中に結果がでます。
              </dir> */}
              <dir className={classes.worning}>
                <span>※</span>
                検査結果が出るまで半日～１日程度かかります。
              </dir>
              <dir className={classes.worning}>
                <span>※</span>ウイルス量が少ないと、検出できない場合も
                <br className={classes.db600} />
                あります。
              </dir>
              {/*<dir className={classes.worning}>
                <span>※</span>陰性証明書、診断書は結果と一緒に<br className={classes.db414} />送信いたします
                </dir>*/}
            </div>
          </div>
        </section>
        <section className={`${classes.section} ${classes.fee}`}>
          <h2 className={classes.sectionTitle}>料金</h2>
          {/* <div className={classes.offText}>
            現在、PCR検査の
            <br className={classes.db600} />
            半額キャンペーン中のため
            <br />
            通常価格 <del>11,000円</del>のところ
          </div> */}
          <div className={classes.feeContent}>
            <div className={classes.feeItem}>
              <div className={`${classes.title}`}>PCR検査</div>
              <div className={`${classes.price}`}>
                <span className={classes.people}> 1 人　</span>5,000<span>円(税込)</span>
              </div>
            </div>
            {/* <a
              className={classes.pdfLink}
              href="https://system.pcr-covid-19.co.jp/PCR検査センター半額チラシ.pdf"
              target="_blank"
              rel="noreferrer"
            >
              詳しくはこちらをご覧ください。
            </a> */}
            {/*0509抗原 <div className={classes.feeItem}>
              <div className={`${classes.title}`}>
                抗原検査
              </div>
              <div className={`${classes.price}`}>
              <span className={classes.people}> 1 人　</span>3,000<span>円(税込)</span>
              </div>
            </div> */}
            {/*
            <div className={classes.feeItem}>
              <div className={classes.feeItemGreen}>
                <div className={classes.option}>－オプション－</div>
                <div className={classes.bottom}>
                  <div className={`${classes.title} ${classes.greenBG}`}>
                    陰性証明書
                  </div>
                  <div className={`${classes.price} ${classes.greenColor}`}>
                    4,400<span>円(税込)</span>
                  </div>
                </div>
              </div>
            </div>*/}
            <div className={classes.message}>
              <span>※</span>
              クレジット決済は、当サイトからご予約いただいた場合のみとなります。
              電話予約の場合は、現金決済のみとなります。
              <br />
              {/* <span>※</span>
              プール方式PCR検査の検査対象者(集団ふるい分け方式)は、厚生労働省の指針に基づき、無症状者で、濃厚接触者に当たらない人が検査対象となります。
              <br />
              2名以上からお申込み可能です。
              <br />
              (2～5名で1検体、6～10名で2検体となります)
              <br />
              <span>※</span>
              プール方式PCR検査結果で陽性の結果が出た場合には、速やかに陽性者の特定検査が必要となります。
              <br />
              その場合、再検査費用はかかりません。
              <br />
              <br />
              <br />
              <NavLink
                to={"/aboutpool"}
                onClick={() => {
                  scrollTop();
                }}
                className={`${classes.btnImgWrapper}`}
              >
                <img className={classes.poolbutton} src={poolbutton} alt="プール方式PCR検査について" />
              </NavLink> */}
              {/*}
              <br />
              <span>※</span>
              陰性証明書は、提携医療機関 <br className={classes.db414} />
              「細谷たかさきクリニック」様にて
              <br className={classes.dn768}/>
          発行する事が可能です。*/}
            </div>
          </div>
        </section>
        <section className={`${classes.section} ${classes.booking}`}>
          <h2 className={classes.sectionTitle}>陰性証明書について</h2>
          <div className={`${classes.text} `}>
            陰性証明書は当検査センターでは発行
            <br className={classes.db414} />
            できません。
            <br />
            必要な方は、最寄りの医療機関まで
            <br className={classes.db414} />
            お問合せください。
          </div>
          <div className={`${classes.text} ${classes.textSert}`}>
            <span>※</span>陰性証明書の発行は、医師による診察が
            <br className={classes.db414} />
            必要と
            <br className={`${classes.db768} ${classes.dn414}`} />
            なります。
            <br />
            <span>※</span>
            料金は当検査センターでの検査料金のほかに、別途各医療機関において諸費用がかかります。
          </div>
        </section>
        <section className={`${classes.section} ${classes.booking}`}>
          <h2 className={classes.sectionTitle}>お申込み</h2>
          <div className={classes.text}>
            検査をご希望の方は、こちらからお申込み
            <br className={classes.db414} />
            ください。
            <br />
            メールアドレスに検査予約用のURLを
            <br className={`${classes.db375}`} />
            送付いたします。
          </div>
          <div className={`${classes.btnImgWrapper}`} onClick={onShowPopup}>
            {/*検査予約カレンダー className={`btn ${classes.btn} ${classes.bookingBtn}`}*/}
            <img src={calendar} alt="検査お申込み_" />
          </div>
          <div className={classes.text}>
            <div className={classes.textSert1}>
              5名様以上で検査をご希望の場合は、
              <br className={`${classes.db768}`} />
              下記までご連絡ください。
            </div>
            <div className={`${classes.text} ${classes.tel}`}>
              <a href="tel:0273868684">☎027-386-8684</a>
            </div>
          </div>
        </section>
        <section className={`${classes.section} ${classes.feedback}`}>
          <h2 className={classes.sectionTitle}>お問合せ</h2>
          <div className={classes.text}>
            検査についてのご質問、気になる点など、
            <br />
            こちらのお問合せフォームから
            <br className={classes.db414} />
            お問合せください。
          </div>
          <NavLink to={"/feedbackForm"} onClick={scrollTop} className={`${classes.btnImgWrapper}`}>
            <img className={classes.handClick2} src={feedback} alt="お問合せはこちらへ" />
            {/*}
            <div className={classes.feedbackLinkText}>
              お問合せ<span>は</span>こちらへ
          </div>   className={classes.feedbackLink}*/}
          </NavLink>
          <div className={classes.text}>
            こちらの「よくある質問」もご参照
            <br className={classes.db414} />
            ください。
          </div>
          <NavLink to={"/qa"} onClick={scrollTop} className={`${classes.btnImgWrapper}`}>
            <img className={classes.handClick2} src={faq} alt="よくある質問" />
            {/*<div className={classes.feedbackLinkText}>よくある質問</div> className={classes.feedbackLink} */}
          </NavLink>
        </section>
      </div>
      <Footer />
      <div className={`${classes.popupWrapper} ${showPopup ? classes.showPopup : ""}`}>
        <motion.form className={`${classes.popup}`} onSubmit={onSubmit} animate={{ scale: showPopup ? 1 : 0.5 }}>
          <div className={classes.innerWrapper}>
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
            <div className={`${classes.popupTitle}`}>
              <span>検査予約</span>
            </div>
            {!thankYou ? (
              <div className={`${classes.popupBody}`}>
                <div>
                  <div className={classes.beforeFormText}>お申込み前に必ずご確認ください。</div>
                  <div className={`${classes.LinksBtns}`}>
                    <NavLink to={"/consent"} target="_blank" className={`${classes.popupBtnImgWrapper}`}>
                      <img src={concent} alt="インフォームドコンセント" />
                      {/*インフォームドコンセント className={`${classes.btn} ${classes.btnLink} ${classes.popupLink}`}*/}
                    </NavLink>
                    <NavLink to={"/rules"} target="_blank" className={`${classes.popupBtnImgWrapper}`}>
                      <img src={rule} alt="検査規約" />
                      {/*検査規約 className={`${classes.btn} ${classes.btnLink} ${classes.popupLink}`}*/}
                    </NavLink>
                    <NavLink to={"/low"} target="_blank" className={`${classes.popupBtnImgWrapper}`}>
                      <img src={low} alt="特定商取引法に基づく表記" />
                      {/*特定商取引法に基づく表記 className={`${classes.btn} ${classes.btnLink} ${classes.popupLink}`}*/}
                    </NavLink>
                  </div>
                  <div className={classes.chekbox}>
                    <input name="checkbox" id="checkbox" type="checkbox" value={email} onChange={onSetEmail} required />
                    <label className={classes.chekboxLabel} htmlFor="checkbox">
                      上記に同意する。
                    </label>
                  </div>
                  <div className={classes.description}>
                    お使いのメールアドレスに検査予約用のURLを
                    <br className={classes.di414} />
                    送付いたします。
                    <br className={classes.dn414} />
                    以下の事項を入力し「メール送信」ボタンを押してください。
                  </div>
                  <div className={classes.formGroup}>
                    <label>検査場所</label>
                    <select
                      disabled
                      value={facility}
                      onChange={onSetFacility}
                      name="AplicantFacility"
                      className={`${classes.border_none}`}
                    >
                      <option value="1">PCR検査センターたかさき</option>
                    </select>
                  </div>
                  {/*
                  <div className={classes.formGroup}>
                    <label>検査方法</label>*/}
                    
                    {/* <select disabled
                      className={`${classes.border_none}`}
                      >                    
                      <option value="false">個別PCR検査</option>
                      <option value="false">抗原検査</option>
                    </select> */}
                    {/*
                    <div className={classes.radio}>
                      <div className={classes.wrap} onClick={() => {
                        checkInspection(1);
                      }}>
                        <input type="radio" name="InspectionMethod" id="inspection1"/>
                        <div className={classes.radioBtn}>
                          {inspectionMethod[0] ? <div className={classes.check}></div> : <></>}
                        </div>
                        <label>PCR検査</label>
                      </div>
                      <div className={classes.wrap} onClick={() => {
                        checkInspection(2);
                      }}>
                        <input type="radio" name="InspectionMethod" id="inspection2"/>
                        <div className={classes.radioBtn}>
                          {inspectionMethod[1] ? <div className={classes.check}></div> : <></>}
                        </div>
                        <label>抗原検査</label>
                      </div>
                    </div>
                  </div>
                    */}
                  <div className={classes.formGroup}>
                    <label htmlFor="">メールアドレス</label>
                    <input
                      type="email"
                      placeholder="例) sample@triel.jp"
                      value={email}
                      onChange={onSetEmail}
                      required
                    />
                  </div>
                  <div className={classes.formGroup}>
                    {/*<img className={classes.press} src={press} alt="press" />*/}
                    <label className={classes.message}>
                      <div className={classes.messageInner}>
                        <span>※</span>ドメイン指定受信の方は、「triel.pcr-covid-19.co.jp」を受信可能にしてください
                      </div>
                      <div>
                        <span>※</span>
                        15分以上経過してもメールが届かない方はメールアドレスをご確認の上再度送信してください
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`${classes.popupBody}`}>
                <div className={classes.thankYouMessage}>
                  ご入力いただいたメールアドレス
                  <br />「 {email}
                  」<br />
                  に、検査予約用のURLを
                  <br className={classes.di414} />
                  送信いたしました。
                  <br />
                  ご確認ください。
                  <br />
                </div>
              </div>
            )}
            <div className={`${classes.popupFooter}`}>
              {!thankYou ? (
                <button className={`btn ${classes.btnImgWrapper} ${classes.popupSubmit}`}>
                  <img src={startmail} alt="メール送信" />
                  {/*メール送信 className={`btn ${classes.popupBtn}`}*/}
                </button>
              ) : (
                <div
                  className={`${classes.btnImgWrapper} ${classes.popupbtn}`}
                  onClick={() => {
                    onShowPopup();
                  }}
                >
                  <img src={closebtn} alt="閉じる" />
                  {/*<div
                  className={`btn ${classes.popupBtn}`}
                  onClick={onShowPopup}
                >
                  閉じる
                </div>*/}
                </div>
              )}
            </div>
          </div>
          <div className={`${classes.close}`} onClick={onShowPopup}>
            <ImCross />
          </div>
        </motion.form>
      </div>
    </div>
  );
};
