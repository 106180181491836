import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Wrapper } from "./components/Wrapper/Wrapper";
import { State } from "./context/State";
function App() {
  return (
    <BrowserRouter>
      <State>
        <Wrapper />
      </State>
    </BrowserRouter>
  );
}

export default App;
