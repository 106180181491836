import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../../context/context";
import classes from "./Calendar.module.scss";
import { ImArrowLeft, ImArrowRight } from "react-icons/im";
import $ from "jquery";

export const Calendar = (props) => {
  const {
    fetchThisWeekAplications,
    thisWeekAplications,
    reserveTime,
    reservedErrorList,
    fetchThisWeekNumberOfPatients,
    thisWeekNumberOfPatients,
    fetchHolidays,
    holidays,
  } = useContext(Context);
  const [week, setWeek] = useState(0);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(9);
  tomorrow.setMinutes(0);
  let initialWeek = [];
  const today = new Date();
  today.setDate(today.getDate() + week + (props.admin ? 0 : 1));
  initialWeek = [new Date(today.toDateString())];
  /*for (let i = 1; i < 5; i++) {
    today.setDate(today.getDate() + 1);
    if (today.getDay() < 6 && today.getDay() > 0) {
      initialWeek.push(new Date(today.toDateString()));
    } else {
      i--;
    }
  }*/
  for (let i = 1; i < 7; i++) {
    today.setDate(today.getDate() + 1);
    initialWeek.push(new Date(today.toDateString()));
  }
  let weekDaysJp = [];
  initialWeek.forEach((el) => {
    //let association = ["月", "火", "水", "木", "金"];
    let association = ["日", "月", "火", "水", "木", "金", "土"];
    weekDaysJp.push(association[el.getDay()]);
  });
  useEffect(() => {
    fetchThisWeekAplications(week,1); //props.facility
    fetchThisWeekNumberOfPatients(week);
    fetchHolidays();
    // eslint-disable-next-line
  }, []);
  const prevWeek = () => {
    setWeek(week - 1);
    fetchThisWeekAplications(week - 1, 1); //props.facility
    fetchThisWeekNumberOfPatients(week - 1);
  };
  const nextWeek = () => {
    setWeek(week + 1);
    fetchThisWeekAplications(week + 1, 1);
    fetchThisWeekNumberOfPatients(week + 1);
  };
  const setTime = (apTime) => {
    apTime.date.setHours(apTime.hour);
    apTime.date.setMinutes(apTime.min);
    reserveTime(apTime.date, 1, props.appId);
    let payload = {
      currentNumberOfPatients: apTime.currentNumberOfPatients,
      time: apTime.hour < 12 ? "午前" : "午後",
    };
    if (props.setCurrentNumberOfPatients) {
      props.setCurrentNumberOfPatients(payload);
    }
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>
        {"1" === "1" //props.facility
          ? "PCR検査センターたかさき"
          : "PCR検査センターとみおか採取所"}
      </div>
      <div className={classes.title}>　</div>
      {reservedErrorList[0] ? (
        <div className={classes.error}>
          同時刻に予約が入ってしまいました。
          <br />
          申し訳ありませんが、別の日時を選択してください。
        </div>
      ) : null}
      <div className={classes.header}>
        <div className={classes.arrowWrapper} onClick={prevWeek}>
          {week ? <ImArrowLeft /> : null}
        </div>
        <div className={classes.interval}>
          {initialWeek[0].getFullYear() +
            "/" +
            (initialWeek[0].getMonth() + 1) +
            "/" +
            initialWeek[0].getDate()}
          {/*initialWeek[0]
            .toLocaleString()
            .slice(
              0,
              initialWeek[0].getMonth() < 9
                ? initialWeek[0].getDate() < 10
                  ? 8
                  : 9
                : initialWeek[0].getDate() < 10
                ? 9
                : 10
            )*/}{" "}
          -{" "}
          {initialWeek[6].getFullYear() +
            "/" +
            (initialWeek[6].getMonth() + 1) +
            "/" +
            initialWeek[6].getDate()}
          {/*initialWeek[6]
            .toLocaleString()
            .slice(
              0,
              initialWeek[6].getMonth() < 9
                ? initialWeek[6].getDate() < 10
                  ? 8
                  : 9
                : initialWeek[6].getDate() < 10
                ? 9
                : 10
            )*/}
        </div>
        <div className={classes.arrowWrapper} onClick={nextWeek}>
          {week <
            (window.innerWidth < 415 ? 11 : window.innerWidth < 769 ? 9 : 7) ||
          props.admin ? (
            <ImArrowRight />
          ) : null}
        </div>
      </div>
      <div className={classes.calendar}>
        {thisWeekAplications.map((day, i) => {
          let numberOfPatients = thisWeekNumberOfPatients.filter(
            (el) => el.date === day.date
          )[0];
          if (
            (window.innerWidth < 769 && i > 4) ||
            (window.innerWidth < 415 && i > 2)
          ) {
            return null;
          } else {
            return (
              <div key={day.date} className={`${classes.day}`}>
                <div className={classes.date}>
                  {initialWeek[i].getFullYear() +
                    "/" +
                    (initialWeek[i].getMonth() + 1) +
                    "/" +
                    initialWeek[i].getDate()}
                  {/*initialWeek[i]
                .toLocaleString()
                .slice(
                  0,
                  initialWeek[i].getMonth() < 9
                    ? initialWeek[i].getDate() < 10
                      ? 8
                      : 9
                    : initialWeek[i].getDate() < 10
                    ? 9
                    : 10
                )*/}
                  ({weekDaysJp[i]})
                </div>
                <div>
                  {day.times.map((time) => {
                    let bacu = numberOfPatients
                      ? time.hour < 12
                        ? numberOfPatients.am >= 90
                          ? true
                          : props.facility === "2"
                          ? true
                          : false
                        : numberOfPatients.pm >= 90
                        ? true
                        : false
                      : false;
                    let dn =
                      (time.hour < 12 || time.hour > 15) &&
                      props.facility === "2"
                        ? true
                        : false;
                    let currentNumberOfPatients = numberOfPatients
                      ? time.hour < 12
                        ? numberOfPatients.am
                        : numberOfPatients.pm
                      : 0;
                    return (
                      <div
                        key={time.label}
                        onClick={
                          !time.value &&
                          !holidays.filter(
                            (el) =>
                              parseInt(el.Date.slice(0, 4))=== (new Date()).getFullYear() &&
                              parseInt(el.Date.slice(5, 7)) ===
                                initialWeek[i].getMonth() + 1 &&
                              parseInt(el.Date.slice(8, 10)) ===
                                initialWeek[i].getDate() &&
                              ((time.hour < 12 && el.Time === "am") ||
                                (time.hour >= 12 && el.Time === "pm") ||
                                el.Time === "ampm")
                          )[0]
                            ? () => {
                                $("html, body").scrollTop(0);
                                setTime({
                                  hour: time.hour,
                                  min: time.min,
                                  date: initialWeek[i],
                                  currentNumberOfPatients:
                                    currentNumberOfPatients,
                                });
                              }
                            : () => {}
                        }
                        className={`${classes.time} ${
                          time.value ||
                          bacu ||
                          holidays.filter(
                            (el) =>
                            parseInt(el.Date.slice(0, 4))=== (new Date()).getFullYear() &&
                              parseInt(el.Date.slice(5, 7)) ===
                                initialWeek[i].getMonth() + 1 &&
                              parseInt(el.Date.slice(8, 10)) ===
                                initialWeek[i].getDate() &&
                              ((time.hour < 12 && el.Time === "am") ||
                                (time.hour >= 12 && el.Time === "pm") ||
                                el.Time === "ampm")
                          )[0]
                            ? classes.bacu
                            : classes.maru
                        } ${dn ? classes.displayNone : ""}`}
                      >
                        {time.label}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
