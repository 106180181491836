import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./AboutGroupAplication.module.scss";
//import viruses from "../../../../../assets/viruses.svg";

export const AboutGroupAplication = (props) => {
  useEffect(() => {
    document.title = "団体でのお申込みについて｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            団体<span>での</span>お申込み<span>について</span>
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.aboutContent}>
            <div className={classes.aboutText}>
              団体でお申込みのお客様は、<br className={classes.di414}/>大変申し訳ありませんが<br/>
              下記の電話番号までご連絡ください。
            </div>
            <div className={classes.tel}><a href="tel:0273868684">☎027-386-8684</a></div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
