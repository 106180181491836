import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import classes from "./SBredirect.module.scss";

export const SBredirect = () => {
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.text}>
        暫くお待ちください。
      </div>
      <Footer />
    </div>
  );
};
