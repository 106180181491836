import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./Consent.module.scss";
//import viruses from "../../../../../assets/viruses.svg";
import press from "../../../../../assets/press.svg";

export const Consent = (props) => {
  useEffect(() => {
    document.title = "インフォームドコンセント｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            インフォームドコンセント
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.aboutContent}>
            <div className={classes.yellowDiv}>
              <img className={classes.press} src={press} alt="press" />
              <div className={classes.yellowDivTitle}>
                新型コロナウイルス
                <br className={classes.db414} />
                PCR検査について・・・
              </div>
              <div className={classes.yellowDivText}>
                本検査は、新型コロナウイルスの病原体（ウイルス）を検出する検査です。
                <br className={classes.dn812} />
                本検査で陽性だった場合、『現在新型コロナウイルスに感染している
                <br className={classes.dn812} />
                可能性』があります。
                <br />
                本検査は、医師・医療機関による確定診断ではございませんので、
                <br className={classes.dn812} />
                結果が陽性だった場合には、必ずお住いの地域の保健所（帰国者・
                接触者相談センターまたは新型コロナウイルス受診相談センター）
                <br className={classes.dn812} />
                に本検査結果の内容をお伝えいただき、ご相談<br className={`${classes.db375}`} />ください。
              </div>
            </div>
            <div className={classes.aboutText}>
              <span>※</span>インフォームドコンセント(informed consent)とは、
              <br className={classes.db414} />
              「説明を
              <br className={`${classes.db812} ${classes.dn812}`} />
              受け、納得
              <br className={classes.dn812} />
              したうえでの同意」という意味です。
              <br className={classes.dn812}/>
              必ずお読みになり、ご理解いただいたうえで本検査
              <br className={classes.db414} />
              をお申込み
              <br className={`${classes.dn812}`} />
              ください。
            </div>
            {/*
            <div className={classes.yellowDiv}>
              <img className={classes.press} src={press} alt="press" />
              <div className={classes.yellowDivTitle}>
                郵送検査について・・・
              </div>
              <div className={classes.yellowDivText}>
                郵送検査には便利な点が多くあります。
                <br />
                例えば、検査のために病院へ行く必要が無く、ご自身の任意のタイミング
                <br />
                で受けることが可能です。
                <br />
                そのような長所がある一方で、検体を自己採取するため、検体に不備が
                <br />
                ある場合は再度検体をご提出いただく場合もございます。
                <br />
                そのような短所もご理解いただいたうえで、検査のお申込みをお願い
                <br />
                いたします。
              </div>
            </div>*/}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
