import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./QA.module.scss";
//import viruses from "../../../../../assets/viruses.svg";

export const QA = (props) => {
  useEffect(() => {
    document.title = "よくある質問｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            よくある質問
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.aboutContent}>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>
                  感染機会からどの程度日数を空けてからの採取が
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  良いですか。
                </div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  潜伏期間は平均5～7日と言われていますが、個人差（1～14日）が
                  <br className={classes.dn600} />
                  あり、陽性が出た場合に、検体採取日からいつ感染したかを特定
                  <br className={classes.dn600} />
                  することはできかねます。
                  <br />
                  何卒ご了承ください。
                </div>
              </div>
            </div>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>
                  検査結果が出るまでに、どのくらい
                  <br className={classes.db414} />
                  かかりますか。
                </div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  {/*0509抗原 PCR検査で半日～１日程度かかり、
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  抗原検査で当日中に結果がでます。 */}
                  弊社での検査サービスでは１日～２日で結果が
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  出ます。
                  <br/>
                  検査のスケジュールによって結果報告が遅れることもあります。
                  <br/>
                  何卒ご了承ください。
                </div>
              </div>
            </div>
            {/*
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>
                  自宅で採取した検体が輸送中に変化し、結果が正しく出ないことは
                  <br />
                  ありますか。
                </div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>採取後速やかにご返送いただければ問題はございません。</div>
              </div>
            </div>*/}
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>PCR検査の結果の精度はどのくらいですか。</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  新型コロナウイルについては、実際に感染していることの把握が
                  <br className={classes.dn600} />
                  難しいため、正確な割合は明確にお知らせできませんが、検査の
                  <br className={classes.dn600} />
                  感度は90％以上、特異度はほぼ100％とお考えいただいて問題は
                  <br className={classes.dn600} />
                  ありません。
                  <div className={classes.aYellowZone}>
                    <div className={classes.aYellowZoneItem}>
                      <div className={classes.aYellowZoneItemLeft}>感度：</div>
                      <div>
                        新型コロナウイルスに<br className={`${classes.db412}`} />感染している人で、
                        <br className={`${classes.db600} ${classes.dn414} ${classes.db412}`} />
                        検査が陽性となる割合
                      </div>
                    </div>
                    <div className={classes.aYellowZoneItem}>
                      <div className={classes.aYellowZoneItemLeft}>
                        特異度：
                      </div>
                      <div>
                        新型コロナウイルスに<br className={`${classes.db412}`} />感染していない人で、
                        <br className={`${classes.db600} ${classes.dn414} ${classes.db412}`} />
                        検査が陰性となる割合
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>結果で陽性が出た場合は<br className={`${classes.db414}`} />どうしたらいいですか。</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  お住いの地域の医療機関に本検査結果の内容を
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  お伝えいただいて、
                  <br className={classes.dn600} />
                  その後の対応を<br className={`${classes.db414}`} />ご相談ください。
                </div>
              </div>
            </div>
            {/*0509抗原 <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>PCR検査と抗原検査の<br className={`${classes.db414}`} />違いはなんですか？</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  PCR検査はウイルスや細菌などの遺伝子を増やして検出する方法で、とても感度が高いです。
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  一方、抗原検査はPCR検査と比べ感度は低いですが、簡単・迅速・安価というメリットがあります。
                  <div className={classes.aYellowZone}>
                    <div className={classes.aYellowZoneItem}>
                      <div className={classes.aYellowZoneItemLeft}>感度：</div>
                      <div>
                        新型コロナウイルスに<br className={`${classes.db412}`} />感染している人で、
                        <br className={`${classes.db600} ${classes.dn414} ${classes.db412}`} />
                        PCR検査が陽性となる割合
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>抗原検査で陰性の場合、<br className={`${classes.db414}`} />コロナに感染していないということですか？</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  抗原検査は特異度が低いため、抗原検査で陰性の場合、コロナ感染を否定するものではありません。
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  引き続き感染対策、体調管理をお勧めいたします。
                  <div className={classes.aYellowZone}>
                    <div className={classes.aYellowZoneItem}>
                      <div className={classes.aYellowZoneItemLeft}>
                        特異度：
                      </div>
                      <div>
                        新型コロナウイルスに<br className={`${classes.db412}`} />感染していない人で、
                        <br className={`${classes.db600} ${classes.dn414} ${classes.db412}`} />
                        検査が陰性となる割合
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>抗原検査を受ける際、<br className={`${classes.db414}`} />注意することはありますか？</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  基本的に症状がある方に限ります。無症状や早期の場合はウイルス量が少なく
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  「偽陰性」（本当は感染しているのに陰性と判定されること）となる場合があります。
                </div>
              </div>
            </div>
            <div className={classes.aboutText}>
              <div className={classes.q}>
                <div className={classes.qa}>Q. </div>
                <div>抗原検査を受けるタイミングはいつがいいですか？</div>
              </div>
              <div className={classes.a}>
                <div className={classes.qa}>A. </div>
                <div>
                  発熱や咳などの症状が出て、少なくても12時間以上経過してから検査を受けることを
                  <br className={`${classes.db600} ${classes.dn414}`} />
                  お勧めします。
                </div>
              </div>
            </div>*/}
          </div> 
        </section>
      </div>
      <Footer />
    </div>
  );
};
