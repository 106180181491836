import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../../context/context";
import { Footer } from "../Footer/Footer";
import { Calendar } from "./Calendar/Calendar";
import { Header } from "../Header/Header";
import classes from "./AplicationForm.module.scss";
import { PatientForm } from "./PatientForm/PatientForm";
import { CheckScreen } from "./CheckScreen/CheckScreen";
import { motion } from "framer-motion";
//import viruses from "../../../../assets/viruses.svg";
import backtotime from "../../../../assets/btns/backtotime.svg";
import checkscreen from "../../../../assets/btns/checkscreen.svg";
import closebtn from "../../../../assets/btns/closebtn.svg";
import $ from "jquery";
import { ImCross } from "react-icons/im";
import { useLocation, useNavigate } from "react-router-dom";

export const AplicationForm = (props) => {
  const {
    submit,
    testDateTime,
    resetTestDateTime,
    facility,
    saveError,
    resetNew,
    fetchCoupon,
    coupon,
    clearCoupon,
  } = useContext(Context);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [Coupon, setCoupon] = useState(
    location.pathname.split("/").reverse()[0].length < 2 ? "" : location.pathname.split("/").reverse()[0]
  );
  useEffect(() => {
    fetchCoupon(Coupon,Aplicant.TypeOfInspection);
    // eslint-disable-next-line
  }, [Coupon]);
  const [error, setError] = useState("");
  /*
  const [Aplicant, setAplicant] = useState({
    acceptCheck: true,
    Surname: "高橋",
    Name: "太郎",
    SurnameKana: "フリガナ",
    NameKana: "フリガナ",
    Gender: "女性",
    Birthday: "2023",
    PhoneNumber: "0801444854",
    AddressPostal: "3702316",
    AddressPrefecture: "群馬県",
    AddressCity: "富岡市富岡",
    AddressStreet: "1444",
    BuildingName: "Triel",
    Price: "5500",
    PriceBeforeDiscount: "",
    PayMethod: "クレジットカード",
    FileIsRequired: false,
    IsGroup: false,
    TypeOfInspection: "PCR検査"
  });
  */
  const [Aplicant, setAplicant] = useState({
    acceptCheck: true,
    Surname: "",
    Name: "",
    SurnameKana: "",
    NameKana: "",
    Gender: "",
    Birthday: "",
    PhoneNumber: "",
    AddressPostal: "",
    AddressPrefecture: "",
    AddressCity: "",
    AddressStreet: "",
    BuildingName: "",
    Price: "",
    PriceBeforeDiscount: "",
    PayMethod: "クレジットカード",
    InspectionMethod: "PCR検査",
    FileIsRequired: false,
    IsGroup: false,
    TypeOfInspection: "PCR検査" //0509抗原
  });
  const errorsList = {
    acceptCheck: "規約に同意を",
    Surname: "お申込み者名前（姓）を",
    Name: "お申込み者名前（名）を",
    SurnameKana: "お申込み者フリガナ（姓）を",
    NameKana: "お申込み者フリガナ（名）を",
    Gender: "お申込み者性別を",
    Birthday: "お申込み者生年月日を",
    PhoneNumber: "電話番号を",
    AddressPostal: "郵便番号を",
    AddressPrefecture: "都道府県を",
    AddressCity: "市区町村を",
    AddressStreet: "番地以降を",
    PayMethod: "お支払い方法を",
    InspectionMethod: "検査方法を",
    SurnameNotKana: "お申込み者フリガナ（姓）は、全角カタカナで",
    NameNotKana: "お申込み者フリガナ（名）は、全角カタカナで",
    TypeOfInspection: "検査方法",
  };
  const errorsListPacient = {
    Surname: "検査希望者名前（姓）を",
    Name: "検査希望者名前（名）を",
    SurnameKana: "検査希望者フリガナ（姓）を",
    NameKana: "検査希望者フリガナ（名）を",
    Gender: "検査希望者性別を",
    Birthday: "検査希望者生年月日を",
    SurnameNotKana: "検査希望者フリガナ（姓）は、全角カタカナで",
    NameNotKana: "検査希望者フリガナ（名）は、全角カタカナで",
  };
  const [checkScreen, setCheckScreen] = useState(false);
  /*const onchangeAcceptCheck = () => {
    if (redAplicant === "acceptCheck") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, acceptCheck: !Aplicant.acceptCheck });
  };*/
  const onchangeAplicantSurname = (event) => {
    if (redAplicant === "Surname") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, Surname: event.target.value });
  };
  const onchangeAplicantName = (event) => {
    if (redAplicant === "Name") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, Name: event.target.value });
  };
  const onchangeAplicantSurnameKana = (event) => {
    setAplicant({ ...Aplicant, SurnameKana: event.target.value });
    if (redAplicant === "SurnameKana") {
      setRedAplicant("");
    }
  };
  const onchangeAplicantNameKana = (event) => {
    if (redAplicant === "NameKana") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, NameKana: event.target.value });
  };
  const onchangeAplicantGender = (event) => {
    setAplicant({ ...Aplicant, Gender: event.target.value });
  };
  const onchangeAplicantPhoneNumber = (event) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === "") {
      if (redAplicant === "PhoneNumber") {
        setRedAplicant("");
      }
      setAplicant({ ...Aplicant, PhoneNumber: event.target.value });
    }
  };
  const onchangeAplicantAddressPostal = (event) => {
    if (event.target.value.match(/^[0-9]+$/) || event.target.value === "") {
      if (redAplicant === "AddressPostal") {
        setRedAplicant("");
      }
      const value = event.target.value;
      const re = /^[0-9]+$/;
      if (value.length === 7 && re.test(value)) {
        $.ajax({
          url: "https://zipcloud.ibsnet.co.jp/api/search?zipcode=" + value.toString(),
          dataType: "jsonp",
          async: false,
          success: function (response) {
            if (response.status === 200) {
              if (response.results != null) {
                setAplicant({
                  ...Aplicant,
                  AddressPrefecture: response.results[0].address1,
                  AddressPostal: value,
                  AddressCity: response.results[0].address2 + "" + response.results[0].address3,
                });
              } else {
                setAplicant({ ...Aplicant, AddressPostal: value });
              }
            }
          },
          error: function () {
            setAplicant({ ...Aplicant, AddressPostal: value });
          },
        });
      } else {
        setAplicant({ ...Aplicant, AddressPostal: value });
      }
    }
  };
  const onchangeAplicantAddressPrefecture = (event) => {
    if (redAplicant === "AddressPrefecture") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, AddressPrefecture: event.target.value });
  };
  const onchangeAplicantAddressCity = (event) => {
    if (redAplicant === "AddressCity") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, AddressCity: event.target.value });
  };
  const onchangeAplicantAddressStreet = (event) => {
    if (redAplicant === "AddressStreet") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, AddressStreet: event.target.value });
  };
  const onchangeAplicantBuildingName = (event) => {
    setAplicant({ ...Aplicant, BuildingName: event.target.value });
  };
  const onchangeAplicantPayMethod = (event) => {
    if (redAplicant === "PayMethod") {
      setRedAplicant("");
    }
    setAplicant({ ...Aplicant, PayMethod: event.target.value });
  };
  const onchangeAplicantTypeOfInspection = (event) => {
    if (redAplicant === "TypeOfInspection") {
      setRedAplicant("");
    }
    fetchCoupon(Coupon,event.target.value);
    setAplicant({ ...Aplicant, TypeOfInspection: event.target.value });
  };
  const onchangeCoupon = (event) => {
    if (redAplicant === "Coupon") {
      setRedAplicant("");
    }
    if (event.target.value === "") {
      setCoupon(null);
    } else {
      fetchCoupon(event.target.value,Aplicant.TypeOfInspection);
      setCoupon(event.target.value);
    }
  };
  /*const onchangeAplicantFileIsRequired = () => {
    setAplicant({ ...Aplicant, FileIsRequired: !Aplicant.FileIsRequired });
  };*/
  const [AplicantIsPatient, setAplicantIsPatient] = useState(false);
  const onchangeAplicantIsPatient = () => {
    if (AplicantIsPatient) {
      Patient.shift();
      onSetNumberOfPatients(Patient.length);
      if (Patient.length === 0) {
        Patient.push(initialPacient);
        onSetNumberOfPatients(1);
      }
      setAplicantIsPatient(!AplicantIsPatient);
    } else {
      let patient = {
        Surname: Aplicant.Surname,
        Name: Aplicant.Name,
        SurnameKana: Aplicant.SurnameKana,
        NameKana: Aplicant.NameKana,
        Gender: Aplicant.Gender,
        Birthday: Aplicant.Birthday,
        BirthdayTrigger: true,
      };
      if (currentNumberOfPatients.currentNumberOfPatients + Patient.length + 1 > 90) {
        onSetNumberOfPatientsPopup(90 - currentNumberOfPatients.currentNumberOfPatients);
        onShowPopup();
      } else {
        if (Patient.length < 4) {
          onSetNumberOfPatientsPopup(0);
          Patient.unshift(patient);
          Patient.pop();
          onSetNumberOfPatients(Patient.length);
          setAplicantIsPatient(!AplicantIsPatient);
        } else {
          onShowPopup(null, "4名様までお願いします");
        }
      }
    }
  };
  /*const initialPacient = {
    Surname: "秋田",
    Name: "明",
    SurnameKana: "あきた",
    NameKana: "あきら",
    Gender: "女性",
    Birthday: "",
    FileIsRequired: false,
  };*/
  const initialPacient = {
    Surname: "",
    Name: "",
    SurnameKana: "",
    NameKana: "",
    Gender: "",
    Birthday: "",
    FileIsRequired: false,
  };
  const [Patient, setPatient] = useState([initialPacient]);
  const [NumberOfPatients, setNumberOfPatients] = useState(1);
  const onSetNumberOfPatients = (value) => {
    setNumberOfPatients(value);
    for (let i = Patient.length; i < value; i++) {
      Patient.push(initialPacient);
    }
    while (Patient.length > value) {
      Patient.pop();
    }
  };
  /*const onchangeNumberOfPatients = (event) => {
    setNumberOfPatients(event.target.value < 1 ? 1 : event.target.value);
    if (event.target.value > 1 && Patient.length < event.target.value) {
      Patient.push(initialPacient);
      PatientEMailError.push(false);
      PatientGenderError.push(false);
    }
    if (event.target.value >= 1 && Patient.length > event.target.value) {
      Patient.pop();
      PatientEMailError.pop();
      PatientGenderError.pop();
    }
  };*/
  const setCurrentPatient = (number, field, value) => {
    setPatient(
      Patient.map((el, i) => {
        el[field] = i === number ? value : el[field];
        return { ...el };
      })
    );
  };
  const [redAplicant, setRedAplicant] = useState("");
  /*const [redPatients, setRedPatients] = useState([]);*/
  const onSetCheckScreen = (value) => {
    if (value) {
      let error = null;
      for (let key in Aplicant) {
        if (!error) {
          setRedAplicant(key);
          error =
            (Aplicant[key] === "" || Aplicant[key] === "0" || !Aplicant[key]) &&
            key !== "BuildingName" &&
            key !== "FileIsRequired" &&
            key !== "Coupon"
              ? errorsList[key]
              : null;
        }
      }
      if (!error) {
        error = !Aplicant.SurnameKana.match(/^[ァ-ンヴー]+$/)
          ? errorsList["SurnameNotKana"]
          : !Aplicant.NameKana.match(/^[ァ-ンヴー]+$/)
          ? errorsList["NameNotKana"]
          : null;
        setRedAplicant(error ? (error === errorsList["SurnameNotKana"] ? "SurnameKana" : "NameKana") : "");
      }
      if (error) {
        onShowPopup(null, error);
      } else {
        $("html, body").scrollTop(0);
        let filePrice = 0;
        [...Patient].forEach((el, i) => {
          filePrice += el.FileIsRequired ? 4400 : 0;
          for (let key in el) {
            if (!error) {
              error =
                el[key] === "" || el[key] === "0"
                  ? (Patient.length > 1 ? i + 1 + "番の" : "") + errorsListPacient[key]
                  : null;
            }
          }
          if (!error) {
            error = !el.SurnameKana.match(/^[ァ-ンヴー]+$/)
              ? (Patient.length > 1 ? i + 1 + "番の" : "") + errorsListPacient["SurnameNotKana"]
              : !el.NameKana.match(/^[ァ-ンヴー]+$/)
              ? (Patient.length > 1 ? i + 1 + "番の" : "") + errorsListPacient["NameNotKana"]
              : null;
            {
              /*setRedAplicant(error?error===errorsListPacient["SurnameNotKana"]?"SurnameKana":"NameKana":"")*/
            }
          }
        });
        if (error) {
          onShowPopup(null, error);
        } else if (!coupon && Coupon !== "" && Coupon !== null) {
          // 存在しないコードを入力した場合は、couponにオブジェクトが入らずにnullとなる
          //0509抗原 error = "クーポンが正しくないか、検査と対応していません。";
          error = "クーポンが正しくありません。";
          setRedAplicant("Coupon");
          onShowPopup(null, error);
          // } else if (coupon.InspectionMethod !== document.getElementById("InspectionMethod").value) {
          //   error = document.getElementById("InspectionMethod").value + "のクーポンではありません。";
          //   setRedAplicant("Coupon");
          //   onShowPopup(null, error);
        } else {
          setCheckScreen(value);
          setAplicant({
            ...Aplicant,
            PriceBeforeDiscount: Patient.length * (Aplicant.TypeOfInspection ==="PCR検査"?5000:3000) + filePrice,
            Price: (coupon ? coupon.Discount : (Aplicant.TypeOfInspection ==="PCR検査"?5000:3000)) * Patient.length,
          });
        }
      }
    } else {
      setCheckScreen(value);
    }
  };
  const resetAplication = () => {
    resetNew();
    setCheckScreen(false);
  };
  let years = [];
  let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  for (let i = new Date().getFullYear(); i > 1921; i--) {
    years.push(i);
  }
  const onSetDays = (year, month) => {
    let temp = year !== "0" && month !== "0" ? new Date(year, month, 0).getDate() : null;
    if (temp) {
      let tempdays = [];
      for (let i = 1; i <= temp; i++) {
        tempdays.push(i);
      }
      setDays(tempdays);
    } else {
      setDays([]);
      setDay(0);
    }
  };
  const [year, setYear] = useState("0");
  const [month, setMonth] = useState("0");
  const [day, setDay] = useState("0");
  const [days, setDays] = useState([]);
  const onYearChange = (event) => {
    if (redAplicant === "Birthday") {
      setRedAplicant("");
    }
    setYear(event.target.value);
    setAplicant({ ...Aplicant, Birthday: "" });
    onSetDays(event.target.value, month);
    setDay(0);
  };
  const onMonthChange = (event) => {
    if (redAplicant === "Birthday") {
      setRedAplicant("");
    }
    setMonth(event.target.value);
    setAplicant({ ...Aplicant, Birthday: "" });
    onSetDays(year, event.target.value);
    setDay(0);
  };
  const onDayChange = (event) => {
    if (redAplicant === "Birthday") {
      setRedAplicant("");
    }
    setDay(event.target.value);
    setAplicant({
      ...Aplicant,
      Birthday: event.target.value !== "0" ? year + "年" + month + "月" + event.target.value + "日" : "",
    });
    if (AplicantIsPatient) {
      setPatient(
        Patient.map((el, i) => {
          if (i === 0) {
            el.Birthday = event.target.value !== "0" ? year + "年" + month + "月" + event.target.value + "日" : "";
          }
          return { ...el };
        })
      );
    }
  };
  const onShowPopup = (event, e) => {
    if (!showPopup) {
      //console.log($(`#${e}`).position());
      //console.log($(`#${e}`));
      $("body").addClass("bodyOverflow");
      $("html, body").scrollTop(($(`#${e}`).position() ? $(`#${e}`).position().top : 0) - 400);
    } else {
      $("body").removeClass("bodyOverflow");
    }
    if (e !== "") {
      setError(e);
    } else {
      setError("");
    }
    setShowPopup(!showPopup);
  };
  let association = ["日", "月", "火", "水", "木", "金", "土"];
  let date = testDateTime ? new Date(testDateTime.toString()) : "";
  const [currentNumberOfPatients, setCurrentNumberOfPatients] = useState({});
  const [showNumberOfPatientsPopup, setNumberOfPatientsPopup] = useState(0);
  const onSetNumberOfPatientsPopup = (value) => {
    setNumberOfPatientsPopup(value);
  };
  const navigate = useNavigate();
  const submitForm = (event) => {
    event.preventDefault();
    submit(Aplicant, Patient, AplicantIsPatient, event.target, coupon);
    $("html, body").scrollTop(0);
    navigate("/SBredirect");
  };
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <div className={`${classes.sectionTitle} ${classes.formTitle}`}>
          {!testDateTime ? "検査予約カレンダー" : !checkScreen ? "新型コロナウイルス検査　お申込み" : "入力内容確認"}
          {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
        </div>
        {!testDateTime ? (
          <div className={classes.calendarWrapper}>
            <Calendar
              facility={props.match ? props.match.params.facility : 1} //props.facility
              setCurrentNumberOfPatients={setCurrentNumberOfPatients}
            />
          </div>
        ) : (
          <div className={classes.formWrapper}>
            {!checkScreen ? (
              <>
                <div className={classes.topInfo}>
                  <div>
                    <div className={classes.beforeFormText}>
                      {/* 希望検査場所{"　　"}
                      {facility === "1" ? "PCR検査センターたかさき" : "PCR検査センターたかさき"}
                      <br /> */}
                      希望日時{"　　　　"}
                      {date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()}(
                      {association[new Date(testDateTime.toString()).getDay()]}){" "}
                      {new Date(testDateTime.toString()).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </div>
                  </div>
                  <div className={classes.btnImgWrapper} onClick={resetTestDateTime}>
                    <img src={backtotime} alt="希望日時の修正" />
                    {/*希望日時の修正 className={`btn ${classes.btn} ${classes.backBtn}`}*/}
                  </div>
                </div>
                {/*<div className={classes.acceptChkWrapper}>
                  <input
                    className={`${classes.acceptChk} ${
                      redAplicant === "acceptCheck" ? classes.errorBorder : ""
                    }`}
                    type="checkbox"
                    onChange={onchangeAcceptCheck}
                    checked={Aplicant.acceptCheck}
                  />
                  <span
                    className={`${
                      redAplicant === "acceptCheck" ? classes.red : ""
                    }`}
                  >
                    規約に同意したうえで、お客様情報の入力へお進みください。　　
                  </span>
                  　　
                </div>*/}
              </>
            ) : null}
            <form onSubmit={submitForm} className={classes.form}>
              <input type="hidden" name="testDateTime" value={testDateTime} />
              <input type="hidden" name="email" value={location.pathname.split("/").reverse()[1]} />
              <input type="hidden" name="facility" value={facility} />
              {checkScreen ? (
                <CheckScreen
                  onSetCheckScreen={onSetCheckScreen}
                  testDateTime={testDateTime}
                  facility={facility === "1" ? "PCR検査センターたかさき" : "PCR検査センターたかさき"}
                  Aplicant={Aplicant}
                  Patient={Patient}
                  AplicantIsPatient={AplicantIsPatient}
                  coupon={coupon !== null ? coupon.CodeName : ""}
                />
              ) : (
                <div>
                  <div className={classes.formHeader}>お申込み者情報入力</div>
                  <div className={classes.visibleForm}>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お申込み者名前（姓）を">
                          お申込み者名前（姓）
                        </span>
                      </label>
                      <input
                        className={`${redAplicant === "Surname" ? classes.errorBorder : ""}`}
                        type="text"
                        name="AplicantSurname"
                        value={`${Aplicant.Surname}`}
                        onChange={onchangeAplicantSurname}
                        placeholder="お申込み者 姓"
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput}>お申込み者名前（名）</span>
                      </label>
                      <input
                        id="お申込み者名前（名）を"
                        className={`${redAplicant === "Name" ? classes.errorBorder : ""}`}
                        type="text"
                        name="AplicantName"
                        value={`${Aplicant.Name}`}
                        onChange={onchangeAplicantName}
                        placeholder="お申込み者 名"
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お申込み者フリガナ（姓）は、全角カタカナで">
                          フリガナ（姓）
                        </span>
                      </label>
                      <input
                        id="お申込み者フリガナ（姓）を"
                        className={`${
                          redAplicant === "SurnameKana" || redAplicant === "SurnameNotKana" ? classes.errorBorder : ""
                        }`}
                        type="text"
                        name="AplicantSurnameKana"
                        value={`${Aplicant.SurnameKana}`}
                        onChange={onchangeAplicantSurnameKana}
                        placeholder="セイ"
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お申込み者フリガナ（名）は、全角カタカナで">
                          フリガナ（名）
                        </span>
                      </label>
                      <input
                        id="お申込み者フリガナ（名）を"
                        className={`${
                          redAplicant === "NameKana" || redAplicant === "NameNotKana" ? classes.errorBorder : ""
                        }`}
                        type="text"
                        name="AplicantNameKana"
                        value={`${Aplicant.NameKana}`}
                        onChange={onchangeAplicantNameKana}
                        placeholder="メイ"
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お申込み者性別を">
                          性別
                        </span>
                      </label>
                      <div className={classes.genderWrapper}>
                        <select
                          className={`${redAplicant === "Gender" ? classes.errorBorder : ""}`}
                          name="AplicantGender"
                          value={`${Aplicant.Gender}`}
                          onChange={onchangeAplicantGender}
                        >
                          <option value="-">-</option>
                          <option value="女性">女性</option>
                          <option value="男性">男性</option>
                        </select>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お申込み者生年月日を">
                          生年月日
                        </span>
                      </label>
                      <div className={`${classes.genderWrapper} ${classes.birthdayWrapper}`}>
                        <select
                          name="year"
                          value={year}
                          onChange={onYearChange}
                          className={`${redAplicant === "Birthday" ? classes.errorBorder : ""}`}
                        >
                          <option value="0">年</option>
                          {years.map((el) => (
                            <option value={el} key={el}>
                              {el}
                            </option>
                          ))}
                        </select>
                        <div className={classes.dateSpacer}>年</div>
                        <select
                          className={`${redAplicant === "Birthday" ? classes.errorBorder : ""}`}
                          name="month"
                          value={month}
                          onChange={onMonthChange}
                        >
                          <option value="0">月</option>
                          {months.map((el) => (
                            <option key={el} value={el}>
                              {el}
                            </option>
                          ))}
                        </select>
                        <div className={classes.dateSpacer}>月</div>
                        <select
                          name="day"
                          value={day}
                          onChange={onDayChange}
                          className={`${redAplicant === "Birthday" ? classes.errorBorder : ""}`}
                        >
                          <option value="0">日</option>
                          {days.map((el) => (
                            <option value={el}>{el}</option>
                          ))}
                        </select>
                        <div className={classes.dateSpacer}>日</div>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput}>電話番号</span>
                      </label>
                      <input
                        id="電話番号を"
                        className={`${redAplicant === "PhoneNumber" ? classes.errorBorder : ""}`}
                        type="text"
                        name="AplicantAplicantPhoneNumber"
                        value={`${Aplicant.PhoneNumber}`}
                        onChange={onchangeAplicantPhoneNumber}
                        placeholder="ハイフンなし"
                      />
                    </div>
                    <div className={classes.formGroup}>
                      <div className={classes.address}>
                        <label>
                          <span className={classes.requireInput}>ご住所</span>
                        </label>
                      </div>
                      <div className={classes.addressLbls}>
                        <label>
                          <span id="郵便番号を">郵便番号</span>
                        </label>
                        <label>
                          <span id="都道府県を">都道府県</span>
                        </label>
                        <label>
                          <span id="市区町村を">市区町村</span>
                        </label>
                        <label>
                          <span id="番地以降を">番地以降</span>
                        </label>
                        <label>法人名</label>
                      </div>
                      <div className={classes.addressInputs}>
                        <input
                          className={`${redAplicant === "AddressPostal" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantAplicantAddressPostal"
                          value={`${Aplicant.AddressPostal}`}
                          onChange={onchangeAplicantAddressPostal}
                          placeholder="ハイフンなし"
                        />
                        <input
                          className={`${redAplicant === "AddressPrefecture" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantAddressPrefecture"
                          value={`${Aplicant.AddressPrefecture}`}
                          onChange={onchangeAplicantAddressPrefecture}
                          placeholder="都道府県"
                        />
                        <input
                          className={`${redAplicant === "AddressCity" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantAddressCity"
                          value={`${Aplicant.AddressCity}`}
                          onChange={onchangeAplicantAddressCity}
                          placeholder="市区町村"
                        />
                        <input
                          className={`${redAplicant === "AddressStreet" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantAddressStreet"
                          value={`${Aplicant.AddressStreet}`}
                          onChange={onchangeAplicantAddressStreet}
                          placeholder="番地以降"
                        />
                        <input
                          type="text"
                          name="AplicantBuildingName"
                          placeholder=""
                          value={`${Aplicant.BuildingName}`}
                          onChange={onchangeAplicantBuildingName}
                        />
                      </div>
                    </div>
                     {/*0509抗原 <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="検査方法を">
                          検査方法
                        </span>
                      </label>
                      <div className={`${classes.genderWrapper} ${classes.PayMethod}`} id={errorsList.TypeOfInspection}>
                        <select
                          className={`${redAplicant === "TypeOfInspection" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantTypeOfInspection"
                          value={`${Aplicant.TypeOfInspection}`}
                          onChange={onchangeAplicantTypeOfInspection}
                        >
                          <option value="">-</option>
                          <option value="PCR検査">PCR検査</option>
                          <option value="抗原検査">抗原検査</option>
                        </select>
                      </div>
                    </div> */}
                    <div className={classes.formGroup}>
                      <label>
                        <span className={classes.requireInput} id="お支払い方法を">
                          お支払い方法
                        </span>
                      </label>
                      <div className={`${classes.genderWrapper} ${classes.PayMethod}`}>
                        <select
                          className={`${redAplicant === "PayMethod" ? classes.errorBorder : ""}`}
                          type="text"
                          name="AplicantPayMethod"
                          value={`${Aplicant.PayMethod}`}
                          onChange={onchangeAplicantPayMethod}
                        >
                          {/*<option value="">支払い方法</option>*/}
                          <option value="クレジットカード">クレジットカード</option>
                          <option value="現地決済（現金支払い）">現地決済（現金支払い）</option>
                          {/*<option value="ソフトバンクまとめて支払い">
                            ソフトバンクまとめて支払い
                          </option>
                          <option value="ドコモ払い">
                          ドコモ払い
                          </option>
                           <option value="窓口">窓口</option> */}
                        </select>
                      </div>
                    </div>
                    <div className={classes.formGroup}>
                      <label className={classes.isPacientChkWrapper}>
                        <input
                          className={classes.isPacientChk}
                          type="checkbox"
                          name="AplicantIsPatient"
                          checked={AplicantIsPatient}
                          onChange={onchangeAplicantIsPatient}
                        />
                        お申込み者と検査希望者が同じ場合には
                        <br className={classes.db600} />
                        チェックしてください。
                      </label>
                    </div>
                    {/*AplicantIsPatient ? (
                    <div className={classes.formGroup}>
                      <label className={`${classes.fileChkWrapper}`}>
                        <div className={`${classes.isPacientChkWrapper}`}>
                          <input
                            className={`${classes.isPacientChk} ${classes.fileChk}`}
                            type="checkbox"
                            name="AplicantFileIsRequired"
                            checked={Aplicant.FileIsRequired}
                            onChange={onchangeAplicantFileIsRequired}
                          />
                          検査証明書希望
                        </div>
                        <span>別途4,400円（税込）がかかります。</span>
                      </label>
                    </div>
                  ) : null*/}
                  </div>
                  <div className={`${classes.formGroup} ${classes.patientNumber}`}>
                    <label>検査を受ける人数</label>
                    <div>
                      <div
                        className={`btn ${classes.btn} ${classes.patientNumberBtn} ${
                          NumberOfPatients === 1 ? classes.active : ""
                        }`}
                        onClick={() => {
                          onSetNumberOfPatients(1);
                        }}
                      >
                        1
                      </div>
                      <div
                        className={`btn ${classes.btn} ${classes.patientNumberBtn} ${
                          NumberOfPatients === 2 ? classes.active : ""
                        }`}
                        onClick={() => {
                          if (currentNumberOfPatients.currentNumberOfPatients > 88) {
                            onSetNumberOfPatientsPopup(90 - currentNumberOfPatients.currentNumberOfPatients);
                            onShowPopup();
                          } else {
                            onSetNumberOfPatientsPopup(0);
                            onSetNumberOfPatients(2);
                          }
                        }}
                      >
                        2
                      </div>
                      {/*<div
                        className={`btn ${classes.btn} ${classes.patientNumberBtn} ${
                          NumberOfPatients === 3 ? classes.active : ""
                        }`}
                        onClick={() => {
                          if (currentNumberOfPatients.currentNumberOfPatients > 87) {
                            onSetNumberOfPatientsPopup(90 - currentNumberOfPatients.currentNumberOfPatients);
                            onShowPopup();
                          } else {
                            onSetNumberOfPatientsPopup(0);
                            onSetNumberOfPatients(3);
                          }
                        }}
                      >
                        3
                      </div>
                      <div
                        className={`btn ${classes.btn} ${classes.patientNumberBtn} ${
                          NumberOfPatients === 4 ? classes.active : ""
                        }`}
                        onClick={() => {
                          if (currentNumberOfPatients.currentNumberOfPatients > 86) {
                            onSetNumberOfPatientsPopup(90 - currentNumberOfPatients.currentNumberOfPatients);
                            onShowPopup();
                          } else {
                            onSetNumberOfPatientsPopup(0);
                            onSetNumberOfPatients(4);
                          }
                        }}
                      >
                        4
                      </div>*/}
                    </div>
                    {/*<input
                  type="number"
                  min="1"
                  max="4"
                  name="NumberOfPatients"
                  value={NumberOfPatients}
                  onChange={onchangeNumberOfPatients}
                />*/}
                  </div>
                  {Patient.map((el, i) => (
                    <PatientForm
                      AplicantIsPatient={AplicantIsPatient}
                      key={i}
                      patients={NumberOfPatients}
                      Patient={Patient[i]}
                      setCurrentPatient={setCurrentPatient}
                      number={i}
                    />
                  ))}
                  <div className={`${classes.formGroup} ${classes.coupon}`}>
                    <label>
                      クーポンをお持ちの方は
                      <br className={classes.db414} />
                      こちらに入れて下さい{" "}
                    </label>
                    <input
                      className={`${coupon ? classes.disabled : ""} ${
                        redAplicant === "Coupon" ? classes.errorBorder : ""
                      }`}
                      type="text"
                      id="クーポンが正しくありません。"
                      name="coupon"
                      disabled={coupon}
                      value={coupon ? coupon.Code : Coupon}
                      onChange={onchangeCoupon}
                    />
                    <div
                      className={classes.clearBtn}
                      onClick={() => {
                        clearCoupon();
                        setCoupon(null);
                        document.getElementById("クーポンが正しくありません。").value = "";
                      }}
                    >
                      クリア
                    </div>
                  </div>

                  <div className={`${classes.formGroup} ${classes.checkBtn}`}>
                    <div
                      className={classes.btnImgWrapper}
                      onClick={() => {
                        onSetCheckScreen(true);
                      }}
                    >
                      <img src={checkscreen} alt="確認画面へ" />
                      {/*確認画面へ className={`btn ${classes.btn} ${classes.btnLink}`}*/}
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        )}
        {saveError.status ? (
          <div className={classes.saveError}>
            <div className={classes.popup}>
              <div className={classes.message}>{saveError.message}</div>
              <div className={`btn btn-primary`} onClick={resetAplication}>
                もう一度やり直してください
              </div>
            </div>
          </div>
        ) : null}
        <div className={`${classes.popupWrapper} ${showPopup ? classes.showPopup : ""}`}>
          <motion.div className={`${classes.popup}`} animate={{ scale: showPopup ? 1 : 0.5 }}>
            <div className={classes.innerWrapper}>
              {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
              <div className={`${classes.popupTitle}`}>
                <span>エラー</span>
              </div>
              <div className={`${classes.popupBody}`}>
                {showNumberOfPatientsPopup ? (
                  <div>
                    {currentNumberOfPatients.time}にあと
                    <span className={classes.errorPeopleNumber}>{showNumberOfPatientsPopup}</span>
                    人しか受け付けれません。
                    <br />
                    それ以上をご希望の方は、
                    <br />
                    日時を選びなおしてください。
                  </div>
                ) : (
                  <>
                    {error}
                    {error !== "4名様までお願いします" ? <br /> : ""}
                    {/*0509抗原 {error !== "4名様までお願いします" && error !== "クーポンが正しくないか、検査と対応していません。" */}
                    {error !== "4名様までお願いします" && error !== "クーポンが正しくありません。"
                      ? "入れてください。"
                      : ""}
                  </>
                )}
              </div>
              <div className={`${classes.popupFooter}`}>
                <div
                  className={`${classes.btnImgWrapper} ${classes.popupbtn}`}
                  onClick={() => {
                    onShowPopup();
                    onSetNumberOfPatientsPopup(0);
                  }}
                >
                  <img src={closebtn} alt="閉じる" />
                  {/*閉じる className={`btn ${classes.popupBtn}`}*/}
                </div>
              </div>
            </div>
            <div
              className={`${classes.close}`}
              onClick={() => {
                onShowPopup();
                onSetNumberOfPatientsPopup(0);
              }}
            >
              <ImCross />
            </div>
          </motion.div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
