import React, {  useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./AboutPool.module.scss";
//import viruses from "../../../../../assets/viruses.svg";

export const AboutPool = (props) => {
  useEffect(() => {
    document.title = "プール方式PCR検査について｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            プール方式PCR検査<span>について</span>
          </div>
          <div className={classes.aboutContent}>            
            <div className={classes.aboutTitle}>
              複数の検体を混ぜて1度に検査する方法です
            </div>
            <div className={classes.aboutText2}>
              陽性確率の低い団体で、複数の検体を混合し同時に検査することにより、費用が抑えられる長所があります。
            </div>
            <div className={classes.aboutTitle}>・プール方式PCR検査の手法</div>
            <div className={classes.aboutText2}>
              <span>①</span>検体を採取
              <br />
              検体を専用の容器で<br className={classes.db375} />各人がそれぞれ採取します。              
            </div>
            <div className={classes.aboutText2}>
              <span>②</span>検体と試薬の混合
              <br />
              検査時に、採取した検体を混ぜ、<br className={classes.db375} />1検体として検査をします。
              <br />
              <span>　※</span>
              1検体に混合できる適正人数は5人までです。
              <br />
              <span>　※</span>
              2～5名で1検体、6～10名で2検体となります。
            </div>
            <div className={classes.aboutText2}>
              <span>③</span>
              検査
              <br />
              PCR検査を実施します。
            </div>
            <div className={classes.aboutText2}>
              <span>④</span>
              結果判定
              <br />
              『検査結果が陰性の場合』
              <br />
              検査を受けた団体を陰性と判断し、<br className={classes.db375} />検査終了となります。
              <br />
              <br />
              『検査結果が陽性の場合』
              <br />
              検体提供者の検体を再度個別に検査します。
              <br />
              個別での検体検査結果により、<br className={classes.db375} />陽性者を特定します。
              <br />
              
              <span>　※</span>
              再検査費用はかかりません
              <br/>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
