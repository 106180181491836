import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./Rules.module.scss";

export const Rules = (props) => {
  useEffect(() => {
    document.title = "新型コロナウイルス検査申込規約｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.pageTitle}>
            <div>
              株式会社トリエル
              <br className={`${classes.db896}`} />
              トリエルPCR検査センターたかさき
            </div>
            <div>新型コロナウイルス感染症検査申込規約</div>
          </div>
          <div className={classes.message}>
            株式会社トリエルトリエルPCR検査センター（以下弊社）の検査サービス（以下検査サービス）の利用を希望される方（以下利用者）は、以下の規約に御同意いただいたものとします。本規約の中の検査サービスは、弊社が監修・運営管理を行います。
          </div>
          {/*<div className={classes.testTypeText}>
            検査サービスは以下の2種類です。
            <br /> ①検査施設来場によるPCR検査
            <br />
            ②キットを郵送するPCR検査
            </div>*/}
          <div className={classes.rules}>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>■第1条（利用者の同意）</div>
              <div className={classes.ruleText}>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>1.</div>
                  <div>
                    明らかに新型コロナウイルス感染症を疑う症状が出ている場合は、弊社の検査サービスをご利用いただけません。
                    <br className={classes.dn896} />
                    症状のある方や感染者の濃厚接触者であれば、保健所や医療機関において自己負担なしで行政検査が受けられます。
                    <br className={classes.dn896} />
                    行政検査を希望する場合は、保健所や医療機関に相談してください。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>2.</div>
                  <div>
                    新型コロナウイルス感染症を疑う症状が出てから2週間以内は、弊社のサービスを<br className={`${classes.db600} ${classes.dn414}`}/>ご利用いただけません。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>3.</div>
                  <div>
                    弊社の検査サービスの結果に対するコメントは、確定診断ではありません。弊社は利用者の疾病に対する一切の責任は
                    <br className={classes.dn896} />
                    負わないものとします。疾病に不安の方は、検査前に最寄りの医療機関等による診察・診断をお勧めします。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>4.</div>
                  <div>
                    検体検査は、弊社またはその指定する検査機関で行います。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>5.</div>
                  <div>
                    利用者の検査結果は、所定の検査所要日数を経過後に弊社から、申込時にご登録いただいたメールアドレス<br className={classes.dn896} />または代表者に送信されます。状況によって所要日数より遅れる場合<br className={`${classes.db600} ${classes.dn414}`}/>もあります。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>6.</div>
                  <div>
                    弊社の検査で利用者に陽性の結果が出た場合は、利用者が検査結果を携え、医療機関（発熱外来）へ直接報告して<br className={classes.dn768} />ください。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>7.</div>
                  <div>
                    検査結果については、商品及び検査サービスの向上のため収集・公表（学会発表等）する場合があります。
                    <br className={classes.dn896} />
                    収集した情報は分析、統計を主としたものであり、お客様の個人を特定するものでは
                    <br className={`${classes.db896} ${classes.dn600} ${classes.db414}`} />
                    ありません。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>8.</div>
                  <div>
                  弊社が必要と認めた時は、利用者の検査結果を、弊社が提携している医療機関や利用者が所属する団体へ報告する場合があります。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div
                    className={`${classes.listItemNumber}`}
                  >
                    9.
                  </div>
                  <div>
                  弊社は、常に個人情報の取扱いに十分留意し、法令を遵守するものとします。
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>
                ■第2条（検査申込フォームの誤記）
              </div>
              <div className={classes.ruleText}>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>1.</div>
                  <div>
                    検査申込フォームに誤記（例えば利用者の住所、電子メールアドレス等の入力間違い）のあった場合、
                    <br className={classes.dn896} />
                    これによって検査サービスの運営上生じた問題に関して、弊社は一切その責を負わないもの
                    <br className={`${classes.db896} ${classes.dn600} ${classes.db414}`} />
                    とします。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>2.</div>
                  <div>
                    検査申込フォームに誤記があった場合、利用者は速やかにその旨を弊社へ通知するものとします。　　　　
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>■第3条（利用者の責任）</div>
              <div className={classes.ruleText}>
                利用者は、本規約に定める事項を遵守すると共に、下記の行為は
                <br className={classes.db896} />
                行わないものとします。
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>1.</div>
                  <div>
                    利用者が、検査サービスの検査を受ける際、所定の申込記入欄に虚偽内容を<br className={`${classes.db600} ${classes.dn414}`}/>記載をする行為。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>2.</div>
                  <div>検査サービスにより、利用できる情報の改竄。</div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>3.</div>
                  <div>
                    有害なコンピュータープログラム等を送信し、書き込む行為。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>4.</div>
                  <div>
                    提供者又は、それから受託している第三者の著作権、知的財産商標権等を侵害、又は侵害する恐れのある行為。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>5.</div>
                  <div>検査サービスを第三者に転売する行為。</div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>6.</div>
                  <div>
                    検査サービスの運営を妨げる行為、又はその恐れのある行為。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>7.</div>
                  <div>他者に成りすまして検査サービスを利用する行為。</div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>8.</div>
                  <div>
                    本人の同意を得ることなく又は詐欺的な手段により他者の個人情報を収集する行為。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>9.</div>
                  <div>
                    その他、法令に違反する行為又は弊社が不適切と判断する行為。
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>
                ■第4条（利用者情報の取り扱い等）
              </div>
              <div className={classes.ruleText}>
                弊社が保有する利用者情報について、個人情報に適用される法令を
                <br className={`${classes.db414} ${classes.dn600}`}/>
                弊社は遵守します。
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>1.</div>
                  <div>
                    弊社は、個人情報を秘密に管理するものとします。但し、
                    <br className={`${classes.db414} ${classes.dn600}`}/>
                    下記の場合は第三者への提供ができるものとします。
                    <div className={classes.listItem}>
                      <div className={classes.listItemNumber}>①</div>
                      <div>利用者の事前の了承が得られた時</div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemNumber}>②</div>
                      <div>利用者から開示を求められた時</div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemNumber}>③</div>
                      <div>法令等により開示を求められた時</div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemNumber}>④</div>
                      <div>利用者の所属する団体等から開示を求められた時</div>
                    </div>
                    <div className={classes.listItem}>
                      <div className={classes.listItemNumber}>⑤</div>
                      <div>本規約に基づく場合</div>
                    </div>
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>2.</div>
                  <div>
                    弊社は、第1条によって利用者が検査サービスに登録して届け出た情報は、弊社が営業上もしくは検査サービスに
                    <br className={classes.dn896} />
                    付随する次回のご利用もしくは新たな検査に関するご案内等に利用できるものとする。
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>■第5条（本規約の変更）</div>
              <div className={classes.ruleText}>
                弊社は、利用者の承諾を得ることなく、利用者に通知することにより、本規約を変更できるものとします。
                <br className={classes.dn896} />
                改訂された本規約は通知した時点で効力が発生します。尚、弊社のWEBサイト上でも公表
                <br className={`${classes.db414} ${classes.dn360}`} />
                いたします。
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>
                ■第6条（サービスの一時中断・停止）
              </div>
              <div className={classes.ruleText}>
                弊社が保有する利用者情報について、個人情報に適用される法令を<br className={`${classes.db414} ${classes.dn600}`}/>弊社は遵守します。
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>1.</div>
                  <div>
                    弊社は、サービス提供に品質維持向上のため、検査サービス環境の保守点検、設備更新、運営上必要性が認められた時、
                    <br className={`${classes.dn896}`} />
                    その目的達成のため、検査サービスの一時中断・停止・変更することがあります。又、天変地異や不可抗力に
                    <br className={`${classes.dn896}`} />
                    よる災害のため
                    検査サービスの一時中断・停止・変更することがあります。これによる利用者の損害については弊社は
                    <br className={`${classes.dn896}`} />
                    責めを負わないものとします。
                  </div>
                </div>
                <div className={classes.listItem}>
                  <div className={classes.listItemNumber}>2.</div>
                  <div>
                    弊社は、営業上その他の理由により、予告なく検査サービスの廃止を決定することが<br className={`${classes.db600} ${classes.dn414}`}/>できます。
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>■第7条（免責責任）</div>
              <div className={classes.ruleText}>
                利用者が検査サービスを通じ知りえる情報等について、その有効性等について弊社はいかなる補償を行いません。
                <br className={`${classes.dn896}`} />
                又、これらに起因して生じる利用者の損害に対して一切の責任は<br className={`${classes.dn414} ${classes.db600}`}/>負いません。
              </div>
            </div>
            <div className={classes.rule}>
              <div className={classes.ruleTitle}>■第8条（合意管轄）</div>
              <div className={classes.ruleText}>
                検査サービスの利用に関して、利用者と弊社で生じた紛争については、前橋地方裁判所高崎支部を第一審の
                <br className={`${classes.dn896}`} />
                専属的合意管轄裁判所<br className={`${classes.db414} ${classes.dn360}`} />とします。
              </div>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
