import React, { useEffect } from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import classes from "./FeedBackThankYou.module.scss";

export const FeedBackThankYou = () => {
  useEffect(() => {
    document.title = "ありがとうございます｜お問合せ｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.text}>
        お問合せをいただき、ありがとうございます。
        <br />
        内容確認後、回答いたします。
        <br />
        お時間をいただき、恐縮ですが
        <br />
        今しばらくお待ちください。
      </div>
      <Footer />
    </div>
  );
};
