import {
  FETCH_THIS_WEEK_APLICATIONS,
  SET_TEST_DATE_TIME,
  SET_RESERVE_ERROR_LIST,
  SET_FACILITY,
  SET_SAVE_ERROR,
  SET_INITIAL_STATE,
  RESET_RESERVE_ERROR_LIST,
  FETCH_THIS_WEEK_NUMBER_OF_PATIENS,
  FETCH_COUPON,
  FETCH_HOLIDAYS,
  CLEAR_COUPON
} from "./types";
const handlers = {
  [FETCH_THIS_WEEK_APLICATIONS]: (state, { payload }) => ({
    ...state,
    thisWeekAplications: payload,
  }),
  [SET_TEST_DATE_TIME]: (state, { payload }) => ({
    ...state,
    testDateTime: payload,
  }),
  [SET_RESERVE_ERROR_LIST]: (state, { payload }) => ({
    ...state,
    reservedErrorList: [...state.reservedErrorList,payload],
  }),
  [RESET_RESERVE_ERROR_LIST]: (state) => ({
    ...state,
    reservedErrorList: [],
  }),
  [SET_FACILITY]: (state, { payload }) => ({
    ...state,
    facility:payload
  }),
  [SET_SAVE_ERROR]: (state, { payload }) => ({
    ...state,
    saveError:payload
  }),
  [FETCH_THIS_WEEK_NUMBER_OF_PATIENS]: (state, { payload }) => ({
    ...state,
    thisWeekNumberOfPatients: payload,
  }),
  [FETCH_COUPON]: (state, { payload }) => ({
    ...state,
    coupon: payload,
  }),
  [FETCH_HOLIDAYS]: (state, { payload }) => ({
    ...state,
    holidays: payload,
  }),
  [SET_INITIAL_STATE]: (state, { payload }) => ({
    ...state,
    initialState: payload,
  }),
  [CLEAR_COUPON]: (state, {payload}) => ({
    ...state,
    coupon: null,
  }),
  DEFAULT: (state) => state,
};

export const reducer = (state, action) => {
  const handle = handlers[action.type] || handlers.DEFAULT;
  let x = handle(state, action);
  if (action.type === "FETCH_HOTSPOTS") {
    //debugger
  }
  return x;
};
