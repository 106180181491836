import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import classes from "./ThankYou.module.scss";
import drivethru from "../../../../assets/btns/drivethru.svg";

export const ThankYou = (props) => {
  useEffect(() => {
    document.title = "ご予約完了｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.text}>
        <div>
          ご予約いただきありがとうございます。
          <br />
          ご予約時に使用したメールアドレスに、<br className={classes.db1024}/>お支払い内容とご予約内容を送付しました。
          <br />
          注意事項をご確認の上、<br className={classes.db414}/>当日はお気をつけてご来場ください。
        </div>
        <NavLink to={`/drivethru/1`} className={classes.btnImgWrapper}>
          <img src={drivethru} alt="ドライブスルーのご案内" />
        </NavLink>
      </div>
      <Footer />
    </div>
  );
};
