import React from "react";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import classes from "./SB.module.scss";

export const SBCancel = () => {
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.text}>
        手続き中に、処理が取り消されました。
        <br />
        お手数ですが、検査予約用に
        <br className={classes.db414} />
        送付した メールのURLより
        <br className={classes.dn414} />
        再度、手続きをお願いいたします。
        <br />
      </div>
      <Footer />
    </div>
  );
};
