import React, { useContext, useEffect, useState } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./FeedbackForm.module.scss";
//import viruses from "../../../../../assets/viruses.svg";
import send from "../../../../../assets/btns/send.svg";
import { Context } from "../../../../../context/context";
import $ from "jquery";

export const FeedbackForm = (props) => {
  const { sendFeedBack } = useContext(Context);
  const [form, setForm] = useState({
    CompanyName: "",
    Name: "",
    eMail: "",
    Message: "",
  });
  useEffect(() => {
    document.title = "お問合せ｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  const onchangeCompanyName = (event) => {
    setForm({ ...form, CompanyName: event.target.value });
  };
  const onchangeName = (event) => {
    setForm({ ...form, Name: event.target.value });
  };
  const onchangeEMail = (event) => {
    setForm({ ...form, eMail: event.target.value });
  };
  const onchangeMessage = (event) => {
    setForm({ ...form, Message: event.target.value });
  };
  const onSubmit = (event) => {
    event.preventDefault();
    sendFeedBack(form);
    $("html, body").scrollTop(0);
  };
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            お問合せフォーム
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.aboutContent}>
            <div className={classes.aboutText}>
              検査についてのご質問、気になる点など、
              <br className={classes.db1024} />
              お問合せください。
            </div>
            <form onSubmit={onSubmit}>
              <div className={classes.formGroup}>
                <label>
                  <span>会社名・団体名</span>
                </label>
                <input
                  type="text"
                  name="CompanyName"
                  value={`${form.CompanyName}`}
                  onChange={onchangeCompanyName}
                />
              </div>
              <div className={classes.formGroup}>
                <label>
                  <span className={classes.requireInput}>お客様氏名</span>
                </label>
                <input
                  type="text"
                  name="Name"
                  value={`${form.Name}`}
                  onChange={onchangeName}
                  required
                />
              </div>
              <div className={classes.formGroup}>
                <label>
                  <span className={classes.requireInput}>メールアドレス</span>
                </label>
                <input
                  type="email"
                  name="eMail"
                  value={`${form.eMail}`}
                  onChange={onchangeEMail}
                  required
                />
              </div>
              <div className={`${classes.formGroup} ${classes.textareaGroup}`}>
                <label>
                  <span className={classes.requireInput}>お問い合わせ内容</span>
                </label>
                <textarea
                  rows="5"
                  cols="33"
                  type="text"
                  name="Message"
                  value={`${form.Message}`}
                  onChange={onchangeMessage}
                  required
                />
              </div>
              <div className={`${classes.btnWrapper}`}>
                {/*<input className={`btn ${classes.btn}`} type="submit" value="送信"/>*/}
                <button className={`btn ${classes.btnImgWrapper}`}>
                  <img src={send} alt="送信" />
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
