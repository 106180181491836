import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { AplicationForm } from "./AplicationForm/AplicationForm";
import { AboutGroupAplication } from "./HomePage/AboutGroupAplication/AboutGroupAplication";
import { FeedbackForm } from "./HomePage/FeedbackForm/FeedbackForm";
import { AboutTest } from "./HomePage/AboutTest/AboutTest";
import { HomePage } from "./HomePage/HomePage";
import { Page404 } from "./Page404/Page404";
import { ThankYou } from "./ThankYou/ThankYou";
import { QA } from "./HomePage/QA/QA";
import { Consent } from "./HomePage/Consent/Consent";
import { Rules } from "./HomePage/Rules/Rules";
import { Low } from "./HomePage/Low/Low";
import { Drivethru } from "./HomePage/Drivethru/Drivethru";
import { SBCancel } from "./SB/SBCancel";
import { SBError } from "./SB/SBError";
import { IoIosArrowDropupCircle } from "react-icons/io";
import classes from "./Home.module.scss";
import $ from "jquery";
import { FeedBackThankYou } from "./FeedBackThankYou/FeedBackThankYou";
import { SBredirect } from "./SBredirect/SBredirect";
import { AboutPool } from "./HomePage/AboutPool/AboutPool";

export const Home = () => {
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    // eslint-disable-next-line
  }, []);
  const [scrollY, setScrollY] = useState(window.scrollY);
  const scrollTop2 = () => {
    $("html, body").animate({ scrollTop: 0 }, "slow");
  };
  const handleScroll = () => {
    setScrollY(window.scrollY);
  };
  return (
    <div>
      <Routes>
        <Route path={"/"} exact element={<HomePage />} />
        <Route path={"/aboutTest"} exact element={<AboutTest />} />
        <Route path={"/drivethru/:facility"} exact element={<Drivethru />} />
        <Route path={"/aboutGroupAplication"} exact element={<AboutGroupAplication />} />
        <Route path={"/feedbackForm"} exact element={<FeedbackForm />} />
        <Route path={"/qa"} exact element={<QA />} />
        <Route path={"/low"} exact element={<Low />} />
        <Route path={"/consent"} exact element={<Consent />} />
        <Route path={"/rules"} exact element={<Rules />} />
        <Route path={"/aboutpool"} exact element={<AboutPool />} />
        <Route path={"/aplication/:email/:facility"} exact element={<AplicationForm />} />
        <Route path={"/aplication/:email/:facility/:coupon"} exact element={<AplicationForm />} />
        <Route path={"/thankyou/:facility"} exact element={<ThankYou />} />
        <Route path={"/feedback/thankyou"} exact element={<FeedBackThankYou />} />
        <Route path={"/api/SB/SBRecive/:id/:facility"} exact element={<ThankYou />} />
        <Route path={"/SB/SBCancel/"} exact element={<SBCancel />} />
        <Route path={"/SB/SBError/"} exact element={<SBError />} />
        <Route path={"/SBredirect/"} exact element={<SBredirect />} />
        <Route path={"/:coupon"} exact element={<HomePage />} />
        <Route path="*" exact={true} element={<Page404 />} />
      </Routes>
      <IoIosArrowDropupCircle
        onClick={scrollTop2}
        className={`${classes.uparrow} ${window.scrollY > 200 ? classes.displayUparrow : ""}`}
      />
    </div>
  );
};
