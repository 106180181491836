import React, { useState } from "react";
import classes from "./PatientForm.module.scss";

export const PatientForm = (props) => {
  const onchangePatientSurname = (event) => {
    props.setCurrentPatient(props.number, "Surname", event.target.value);
  };
  const onchangePatientName = (event) => {
    props.setCurrentPatient(props.number, "Name", event.target.value);
  };
  const onchangePatientSurnameKana = (event) => {
    props.setCurrentPatient(props.number, "SurnameKana", event.target.value);
  };
  const onchangePatientNameKana = (event) => {
    props.setCurrentPatient(props.number, "NameKana", event.target.value);
  };
  const onchangePatientGender = (event) => {
    props.setCurrentPatient(props.number, "Gender", event.target.value);
  };
  /*const onchangePatientFileIsRequired = () => {
    props.setCurrentPatient(
      props.number,
      "FileIsRequired",
      !props.Patient.FileIsRequired
    );
  };*/
  let years = [];
  let months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  for (let i = new Date().getFullYear(); i > 1921; i--) {
    years.push(i);
  }
  const onSetDays = (year, month, trigger) => {
    let temp =
      year !== "0" && month !== "0" ? new Date(year, month, 0).getDate() : null;
    let tempdays = [];
    if (temp) {
      for (let i = 1; i <= temp; i++) {
        tempdays.push(i);
      }
    }
    if (trigger) {
      return tempdays;
    } else {
      setDays(tempdays);
    }
  };
  const [year, setYear] = useState(
    props.Patient.Birthday.length > 0
      ? props.Patient.Birthday.substring(0, 4)
      : "0"
  );
  const [month, setMonth] = useState(
    props.Patient.Birthday.length > 0
      ? parseInt(props.Patient.Birthday.substring(5, 7))
      : "0"
  );
  const [days, setDays] = useState(onSetDays(year, month, true) || "0");
  const [day, setDay] = useState(props.Patient.Birthday.substring(8, 10));

  const onYearChange = (event) => {
    setYear(event.target.value);
    onSetDays(event.target.value, month);
    setDay("0");
    props.setCurrentPatient(props.number, "Birthday", "");
  };
  const onMonthChange = (event) => {
    setMonth(event.target.value);
    onSetDays(year, event.target.value);
    setDay("0");
    props.setCurrentPatient(props.number, "Birthday", "");
  };
  const onDayChange = (event) => {
    setDay(event.target.value);
    setBirthday(year, month, event.target.value);
  };
  const setBirthday = (year, month, day) => {
    props.setCurrentPatient(
      props.number,
      "Birthday",
      year + "年" + month + "月" + day + "日"
    );
  };
  return (
    <div className={classes.wrapper}>
      <div className={classes.formHeader}>検査希望者情報入力</div>
      <div className={classes.visibleForm}>
        <div className={classes.formGroup}>
          <label>
            <span className={classes.requireInput}>名前（姓）</span>
          </label>
          <input
            id={
              props.patients > 1
                ? props.number + 1 + "番の検査希望者名前（姓）を"
                : "検査希望者名前（姓）を"
            }
            disabled={props.AplicantIsPatient && props.number === 0}
            type="text"
            name="PatientSurname"
            value={`${props.Patient.Surname}`}
            onChange={onchangePatientSurname}
            required
            placeholder="検査希望者 姓"
          />
        </div>
        <div className={classes.formGroup}>
          <label>
            <span className={classes.requireInput}>名前（名）</span>
          </label>
          <input
            id={
              props.patients > 1
                ? props.number + 1 + "番の検査希望者名前（名）を"
                : "検査希望者名前（名）を"
            }
            disabled={props.AplicantIsPatient && props.number === 0}
            type="text"
            name="PatientName"
            value={`${props.Patient.Name}`}
            onChange={onchangePatientName}
            required
            placeholder="検査希望者 名"
          />
        </div>
        <div className={classes.formGroup}>
          <label>
            <span
              className={classes.requireInput}
              id={
                props.patients > 1
                  ? props.number + 1 + "番の検査希望者フリガナ（姓）を"
                  : "検査希望者フリガナ（姓）を"
              }
            >
              フリガナ（姓）
            </span>
          </label>
          <input
            id={
              props.patients > 1
                ? props.number +
                  1 +
                  "番の検査希望者フリガナ（姓）は、全角カタカナで"
                : "検査希望者フリガナ（姓）は、全角カタカナで"
            }
            disabled={props.AplicantIsPatient && props.number === 0}
            type="text"
            name="PatientSurnameKana"
            value={`${props.Patient.SurnameKana}`}
            onChange={onchangePatientSurnameKana}
            required
            placeholder="セイ"
          />
        </div>
        <div className={classes.formGroup}>
          <label>
            <span
              className={classes.requireInput}
              id={
                props.patients > 1
                  ? props.number + 1 + "番の検査希望者フリガナ（名）を"
                  : "検査希望者フリガナ（名）を"
              }
            >
              フリガナ（名）
            </span>
          </label>
          <input
            id={
              props.patients > 1
                ? props.number +
                  1 +
                  "番の検査希望者フリガナ（名）は、全角カタカナで"
                : "検査希望者フリガナ（名）は、全角カタカナで"
            }
            disabled={props.AplicantIsPatient && props.number === 0}
            type="text"
            name="PatientNameKana"
            value={`${props.Patient.NameKana}`}
            onChange={onchangePatientNameKana}
            required
            placeholder="メイ"
          />
        </div>
        <div className={classes.formGroup}>
          <label>
            <span className={classes.requireInput}>性別</span>
          </label>
          <div
            className={classes.genderWrapper}
            id={
              props.patients > 1
                ? props.number + 1 + "番の検査希望者性別を"
                : "検査希望者性別を"
            }
          >
            <select
              disabled={props.AplicantIsPatient && props.number === 0}
              name="PatientGender"
              value={`${props.Patient.Gender}`}
              onChange={onchangePatientGender}
            >
              <option value="">-</option>
              <option value="女性">女性</option>
              <option value="男性">男性</option>
            </select>
            {props.PatientGenderError ? (
              <div className={classes.error}>PatientGender Not set</div>
            ) : null}
          </div>
        </div>
        <div className={classes.formGroup}>
          <label>
            <span
              className={classes.requireInput}
              id={
                props.patients > 1
                  ? props.number + 1 + "番の検査希望者生年月日を"
                  : "検査希望者生年月日を"
              }
            >
              生年月日
            </span>
          </label>
          <div
            className={`${classes.genderWrapper} ${classes.birthdayWrapper} ${
              props.Patient.BirthdayTrigger ? classes.isPatient : ""
            }`}
          >
            {props.Patient.BirthdayTrigger ? (
              props.Patient.Birthday
            ) : (
              <>
                <select
                  name="year"
                  value={year}
                  onChange={onYearChange}
                  disabled={props.AplicantIsPatient && props.number === 0}
                >
                  <option value="0">年</option>
                  {years.map((el) => (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
                <div className={classes.dateSpacer}>年</div>
                <select
                  name="month"
                  value={month}
                  onChange={onMonthChange}
                  disabled={props.AplicantIsPatient && props.number === 0}
                >
                  <option value="0">月</option>
                  {months.map((el) => (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  ))}
                </select>
                <div className={classes.dateSpacer}>月</div>
                <select
                  name="day"
                  value={day}
                  onChange={onDayChange}
                  disabled={props.AplicantIsPatient && props.number === 0}
                >
                  <option value="0">日</option>
                  {days.map((el) => (
                    <option value={el}>{el}</option>
                  ))}
                </select>
                <div className={classes.dateSpacer}>日</div>
                {props.PatientBirthdayError ? (
                  <div className={`${classes.error} ${classes.birthdayError}`}>
                    PatientBirthday Not set
                  </div>
                ) : null}
              </>
            )}
          </div>
        </div>
        {/*
        <div className={classes.formGroup}>
          <label className={`${classes.fileChkWrapper}`}>
            <div className={`${classes.isPacientChkWrapper}`}>
              <input
                className={`${classes.isPacientChk} ${classes.fileChk}`}
                type="checkbox"
                name="PatientFileIsRequired"
                checked={props.Patient.FileIsRequired}
                onChange={onchangePatientFileIsRequired}
              />
              検査証明書希望
            </div>
            <span>別途4,400円（税込）がかかります。</span>
          </label>
        </div>*/}
      </div>
    </div>
  );
};
