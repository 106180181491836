import React, { useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./Drivethru.module.scss";
import img from "../../../../../assets/DriveThru.png";
import { Takasaki } from "./Takasaki/Takasaki";
import { Tomioka } from "./Tomioka/Tomioka";

export const Drivethru = (props) => {
  useEffect(() => {
    document.title = "ドライブスルー｜トリエルPCR検査";
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      {
        {
          1: <Takasaki />,
          2: <Tomioka />
        }[1]
      }
      <Footer />
    </div>
  );
};
