import React, {  useEffect } from "react";
import { Header } from "../../Header/Header";
import { Footer } from "../../Footer/Footer";
import classes from "./AboutTest.module.scss";
//import viruses from "../../../../../assets/viruses.svg";

export const AboutTest = (props) => {
  useEffect(() => {
    document.title = "PCR検査について｜トリエルPCR検査"
    // eslint-disable-next-line
  }, []);
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.content}>
        <section className={`${classes.section}`}>
          <div className={classes.sectionTitle}>
            弊社<span>の</span>PCR検査<span>について</span>
            {/*<img className={classes.viruses} src={viruses} alt="viruses" />*/}
          </div>
          <div className={classes.aboutContent}>
            <div className={classes.aboutTitle}>
              ・唾液採取の検査キットについて
            </div>
            <div className={`${classes.aboutText} `}>
              弊社では唾液を採取して行うPCR検査を採用しております。
              <br className={classes.dn896} />
              厚生労働科学研究においても、鼻咽頭のぬぐい液を検体とする
              <br className={classes.dn896} />
              PCR検査と、同じ精度ということが実証されています。
              <br/>
              鼻咽頭のぬぐい液を採取する方法とは違い、専用容器に唾液
              <br className={classes.dn896} />
              を入れるだけなので、医師や看護師の手を煩わすことなく簡単
              <br className={classes.dn896} />
              に検体を採取できます。
            </div>
            <div className={classes.aboutTitle}>・PCR検査の手法</div>
            <div className={classes.aboutText2}>
              <span>①</span>検体を採取
              <br />
              検体採取容器で唾液の採取を行って<br className={classes.db375} />いただきます。
            </div>
            <div className={classes.aboutText2}>
              <span>②</span>試薬と混合
              <br />
              唾液（検体）と試薬を混合します。
            </div>
            <div className={classes.aboutText2}>
              <span>③</span>
              熱処理
              <br />
              熱を加え、ウイルスを不活化します。
            </div>
            <div className={classes.aboutText2}>
              <span>④</span>
              反応液と混合
              <br />
              熱処理後、検体を反応液と混合します。
            </div>
            <div className={classes.aboutText2}>
              <span>⑤</span>
              PCR検査機へ
              <br />
              検体を測定機へセットします。
              <br />
              そして、ウイルス特有の遺伝子配列を増幅し、
              <br className={classes.db414} />検出します。
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};
