export const FETCH_APLICATIONS_LIST = "FETCH_APLICATIONS_LIST"
export const FETCH_THIS_WEEK_APLICATIONS = "FETCH_THIS_WEEK_APLICATIONS"
export const SET_TEST_DATE_TIME = "SET_TEST_DATE_TIME"
export const SET_RESERVE_ERROR_LIST = "SET_RESERVE_ERROR_LIST"
export const RESET_RESERVE_ERROR_LIST = "RESET_RESERVE_ERROR_LIST"
export const SET_FACILITY = "SET_FACILITY"
export const SET_SAVE_ERROR = "SET_SAVE_ERROR"
export const FETCH_THIS_WEEK_NUMBER_OF_PATIENS = "FETCH_THIS_WEEK_NUMBER_OF_PATIENS"
export const FETCH_COUPON = "FETCH_COUPON"
export const SET_INITIAL_STATE = "SET_INITIAL_STATE"
export const FETCH_HOLIDAYS = "FETCH_HOLIDAYS"
export const CLEAR_COUPON = "CLEAR_COUPON"