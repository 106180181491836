import React from "react";
import classes from "./Page404.module.scss";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

export const Page404 = () => {
  return (
    <div className={classes.wrapper}>
      <Header />
      <div className={classes.text}>
        <span>404</span>
        <br />
        ページが見つかりません
      </div>
      <Footer />
    </div>
  );
};
