import React from "react";
import classes from "./Footer.module.scss";
import triellogo from "../../../../assets/triellogo_2.svg";

export const Footer = () => {
  return (
    <>
      <div className={classes.wrapper2}>
        <div className={classes.footer}>
          <div className={classes.footerItem}>
            <a href="https://triel.ltd/" target="_blank" rel="noreferrer" className={classes.kabu}>
              <img src={triellogo} alt="株式会社トリエル" /> 株式会社トリエル
            </a>
            <div className={classes.name}>トリエルPCR検査センター</div>
          </div>
          <div className={classes.footerItem}>
            <a href="tel:0273868684" className={classes.tel}>☎ 027-386-8684</a>
            <a href="https://goo.gl/maps/bS26cc9QmQsFLxpm9" target="_blank" rel="noreferrer" className={classes.address}>
              〒370-0036群馬県高崎市南大類町888-1
            </a>
          </div>
        </div>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.footer}>
          <span className={classes.copyrights}>
            Ⓒ2021-Triel.,Ltd.-All Rights Reserved.
          </span>
        </div>
      </div>
    </>
  );
};
