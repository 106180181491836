import React from "react";
import classes from "./CheckScreen.module.scss";
//import ScriptTag from "react-script-tag";
import pay from "../../../../../assets/btns/pay.svg";
import madogutithy from "../../../../../assets/btns/madogutithy.svg";
import backtoform from "../../../../../assets/btns/backtoform.svg";

export const CheckScreen = (props) => {
  let association = ["日", "月", "火", "水", "木", "金", "土"];
  let date = new Date(props.testDateTime);
  return (
    <div className={classes.wrapper}>
      <div className={classes.datePlaceBackWrapper}>
        {/* 希望検査場所　　PCR検査センターたかさき
        <br /> */}
        希望日時　　　　
        {date
          .toLocaleString()
          .slice(
            0,
            date.getMonth() < 9
              ? date.getDate() < 10
                ? 8
                : 9
              : date.getDate() < 10
              ? 9
              : 10
          )}
        {/*({association[date.getDay()]}) {date.toLocaleTimeString().slice(0, 5)}}*/}
        ({association[date.getDay()]}){" "}
        {date.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })}
        <br />
        <div
          className={`btn ${classes.btnImgWrapper} ${classes.backtoform}`}
          onClick={() => {
            window.scrollTo(0, 0);
            props.onSetCheckScreen(false);
          }}
        >
          <img src={backtoform} alt="入力内容の修正" />
          {/*入力内容の修正 className={`btn ${classes.btn}`}*/}
        </div>
      </div>
      <table>
        <tbody>
          <tr>
            <th>お申込み者</th>
            <td>
              {props.Aplicant.Surname}
              {props.Aplicant.Name} ({props.Aplicant.SurnameKana}
              {props.Aplicant.NameKana})
            </td>
          </tr>
          <tr>
            <th>性別</th>
            <td>{props.Aplicant.Gender}</td>
          </tr>
          <tr>
            <th>生年月日</th>
            <td>{props.Aplicant.Birthday}</td>
          </tr>
          <tr>
            <th>電話番号</th>
            <td>{props.Aplicant.PhoneNumber}</td>
          </tr>
          <tr>
            <th>住所</th>
            <td>
              {props.Aplicant.AddressPostal} {props.Aplicant.AddressPrefecture}
              {props.Aplicant.AddressCity} {props.Aplicant.AddressStreet}{" "}
              {props.Aplicant.Company}
            </td>
          </tr>
          <tr>
            <th>検査希望者数</th>
            <td>{props.Patient.length}人</td>
          </tr>
          {/*0509抗原 <tr>
            <th>検査方法</th>
            <td>{props.Aplicant.TypeOfInspection}</td>
          </tr> */}
          <tr>
            <th>支払い総額</th>
            {props.Aplicant.PriceBeforeDiscount === props.Aplicant.Price ? (
              <td> {Number(props.Aplicant.Price).toLocaleString()}円</td>
            ) : (
              <td>
                {" "}
                <span className={classes.lineThrou}>
                  {Number(props.Aplicant.PriceBeforeDiscount).toLocaleString()}
                  円
                </span>{" "}
                <span className={classes.discount}>
                {Number(props.Aplicant.Price).toLocaleString()}円
                </span>
              </td>
            )}
          </tr>
          {Number(props.Aplicant.Price)!==0?
          <tr>
            <th className={classes.lastTh}>お支払い方法</th>
            <td>{props.Aplicant.PayMethod}</td>
          </tr>:""}
        </tbody>
      </table>
      {/*props.AplicantIsPatient
        ? <div className={classes.aplicantIsPatient}>※検査ご希望者は、お申込み者ご本人となっています。</div>
      :*/}
      {props.Patient.map((patient) => (
        <table key={patient.Name}>
          <tbody>
            <tr>
              <th>検査希望者</th>
              <td>
                {patient.Surname}
                {patient.Name} ({patient.SurnameKana}
                {patient.NameKana})
              </td>
            </tr>
            <tr>
              <th>性別</th>
              <td>{patient.Gender}</td>
            </tr>
            <tr>
              <th className={classes.lastTh}>生年月日</th>
              <td>{patient.Birthday}</td>
            </tr>
          </tbody>
        </table>
      ))}
      <div className={classes.btnGroup}>
        <div className={classes.formGroup}>
          <button className={`btn ${classes.btnImgWrapper}`}>
            {props.Aplicant.PayMethod !== "クレジットカード" || !Number(props.Aplicant.Price) ? (
              <img src={madogutithy} alt="予約確定" />
            ) : (
              <img src={pay} alt="お支払い" />
            )}
          </button>
          {/*<input
            className={`btn ${classes.btn}`}
            type="submit"
            value="お支払い"
          />*/}
        </div>
        {/*<div className={`btn ${classes.btn}`} onClick={()=>{window.f_submit(777)}}>お支払い (SB)</div>*/}
      </div>
      {props.Aplicant.PayMethod !== "クレジットカード" || !Number(props.Aplicant.Price) ? null : (
        <>
        <div className={classes.bottomText}>
            ※お支払いがクレジットカードの場合、
            <br />
            ソフトバンクペイメントの支払い画面へ移動します。
        </div>
        </>
      )}
      {props.coupon === "学生割引" ? (
            <div className={classes.bottomText}>※学生割引ご利用の場合、<br/>学生証をお忘れなくお持ちください。</div>
          ) : null}
    </div>
  );
};
