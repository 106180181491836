import classes from "./Takasaki.module.scss";
import img from "../../../../../../assets/DriveThru.png";

export const Takasaki = () => {
  return (
    <div className={classes.content}>
      <section className={`${classes.section}`}>
        <div className={classes.sectionTitle}>ドライブスルーのご案内</div>
        <div className={classes.aboutContent}>
          <div className={classes.aboutText}>
            今回は検査をお申込みいただきまして、誠にありがとうございます。
            <br className={`${classes.dn600}`} />
            弊社では、感染防止のためドライブスルー形式で実施して
            <br className={`${classes.dn600} `} />
            います。検査の流れをご案内いたします。
          </div>
          <div className={classes.borderedText}>
            <div className={classes.text}>
              トリエルＰＣＲ検査センターたかさき
              <br />
              ドライブスルー専用電話番号
            </div>
            <div className={classes.tel}>☎027-386-8684</div>
          </div>
          <div className={classes.numberTitle}>検査の手順</div>
          <div className={`${classes.aboutText2} ${classes.aboutText}`}>
            <div className={`${classes.text2element}`}>
              <span>①</span>
              細谷たかさきクリニックの駐車場に到着しましたら院内には入らず、
              <br className={`${classes.dn600}`} />
              車内から上記の電話番号までご連絡ください。
            </div>
            <div className={`${classes.text2element}`}>
              <span>②</span>
              順番になりましたらスタッフがご案内しますので、車内で電話を
              <br className={`${classes.dn600}`} />
              お待ちください。
            </div>
            <div className={`${classes.text2element}`}>
              <span>③</span>
              検体採取は駐車場かドライブスルー窓口付近で行います。そのまま駐車場で
              <br className={`${classes.dn600}`} />
              採取していただくか、下図のようにドライブスルー窓口まで移動して
              <br className={`${classes.dn600}`} />
              いただきます。
            </div>
            <div className={`${classes.text2element}`}>
              <span>④</span>
              検体採取容器をお渡しいたしますので、5mlまで唾液を入れてください。
            </div>
            <div className={`${classes.text2element}`}>
              <span>⑤</span>
              検体が採取できましたら電話でご連絡ください。スタッフの指示に従って
              <br className={`${classes.dn600}`} />
              ドライブスルー窓口で検体を渡し、検体受領書をお受け取りください。
            </div>
          </div>
          <div className={classes.worningsWrapper}>
            <div className={classes.worningsTitle}>－ 注意事項 －</div>
            <div className={classes.worning}>
              <span>・</span>
              検査30分前より飲食、飲水、歯磨きはしないで
              <br className={`${classes.db414} ${classes.dn393}`} />
              ください。
            </div>
            <div className={classes.worning}>
              <span>・</span>飴やガムは食べないでください。
            </div>
            <div className={classes.worning}>
              <span>・</span>
              停車中はスタッフの指示以外で、窓を開けたり外へ出たりしないでください。
            </div>
            <div className={classes.worning}>
              <span>・</span>
              唾液が出ないときは、レモンや梅干しなどすっぱい物を想像してください。
            </div>
            <div className={classes.worning}>
              <span>・</span>
              唾液は泡立てずに容器へ入れてください。
              <br className={`${classes.db414} ${classes.dn360}`} />
              2～3回に分けてゆっくり入れると
              <br className={`${classes.dn600}`} />
              泡立ちにくく
              <br className={`${classes.db414} ${classes.dn393}`} />
              なります。
            </div>
          </div>
          <div className={classes.imgWrapper}>
            <img src={img} alt="DriveThru" />
          </div>
        </div>
      </section>
    </div>
  );
};
