import React, { useReducer } from "react";
import { Context } from "./context";
import { reducer } from "./reducer";
import axios from "axios";
import {
  FETCH_THIS_WEEK_APLICATIONS,
  SET_TEST_DATE_TIME,
  SET_RESERVE_ERROR_LIST,
  SET_FACILITY,
  SET_SAVE_ERROR,
  SET_INITIAL_STATE,
  RESET_RESERVE_ERROR_LIST,
  FETCH_THIS_WEEK_NUMBER_OF_PATIENS,
  FETCH_COUPON,
  FETCH_HOLIDAYS,
  CLEAR_COUPON,
} from "./types";
import { useNavigate } from "react-router-dom";

export const State = ({ children }) => {
  const server = process.env.REACT_APP_SERVER;
  const initialState = {
    saveError: { status: false, message: "" },
    testDateTime: null,
    reservedErrorList: [],
    thisWeekAplications: [],
    thisWeekNumberOfPatients: [],
    facility: 1,
    initialState: {
      facility: "1",
      date: new Date().toISOString().slice(0, 10),
    },
    coupon: null,
    holidays: [],
  };
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  let myHeaders = {
    headers: {
      Accept: "application/json",
    },
  };
  const resetNew = () => {
    let payload = { status: false, message: "" };
    dispatch({ type: SET_SAVE_ERROR, payload });
    payload = null;
    dispatch({ type: SET_TEST_DATE_TIME, payload });
  };
  const submit = async (Aplicant, Patient, AplicantIsPatient, form, coupon) => {
    let payload = {
      Aplicant,
      Patient,
      AplicantIsPatient: false,
      hiddenData: {
        testDateTime: form.elements["testDateTime"].value,
        email: form.elements["email"].value,
        facility: form.elements["facility"].value,
        coupon: coupon ? coupon.CouponId : 0,
      },
      zero: Aplicant.Price ? true : false,
    };
    axios.post(server + "/aplication/add", payload, myHeaders).then((res) => {
      if (res.data.error) {
        let payload = { status: true, message: res.data.message };
        dispatch({ type: SET_SAVE_ERROR, payload });
      } else {
        //dispatch({ type: RESET_SAVE_ERROR });
        if (Aplicant.PayMethod === "クレジットカード" && Aplicant.Price) {
          window.f_submit(res.data.id, Aplicant.PayMethod, Aplicant.Price.toString(), form.elements["facility"].value);
        } else {
          navigate(`/thankyou/${form.elements["facility"].value}`);
          axios.post(server + "/SB/CGI/" + res.data.id, payload, myHeaders);
        }
        resetNew();
      }
    });
  };
  const fetchThisWeekAplications = async (day, facility) => {
    axios.get(server + "/aplication/thisweekaplications/" + facility, myHeaders).then((res) => {
      const today = new Date();
      today.setDate(today.getDate() + day + 1);
      /*let initialWeek = [new Date(today.toDateString())];
        for (let i = 1; i < 5; i++) {
          today.setDate(today.getDate() + 1);
          if (today.getDay() < 6 && today.getDay() > 0) {
            initialWeek.push(new Date(today.toDateString()));
          } else {
            i--;
          }
        }*/
      let initialWeek = [new Date(today.toDateString())];
      for (let i = 1; i < 7; i++) {
        today.setDate(today.getDate() + 1);
        initialWeek.push(new Date(today.toDateString()));
      }
      let payload = [];
      payload = initialWeek.map((weekDay) => {
        let aplications = res.data?.filter((aplicationDate) => {
          aplicationDate = new Date(aplicationDate.TestDateTime);
          aplicationDate.setHours(aplicationDate.getHours() - 9);
          aplicationDate.setHours(0);
          aplicationDate.setMinutes(0);
          aplicationDate.setSeconds(0);
          return weekDay.getTime() === aplicationDate.getTime();
        });
        let groupAplications = [];
        groupAplications = aplications.filter((ap) => ap.IsGroup);
        if (aplications.length > 0) {
          aplications = aplications.map((aplication) => {
            aplication = new Date(aplication.TestDateTime);
            aplication.setHours(aplication.getHours() - 9);
            return aplication;
          });
        }
        let times = [];
        let tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        for (let hour = 9; hour < 17; hour++) {
          for (let minutes = 0; minutes < 2; minutes++) {
            let time = {
              hour: hour,
              min: minutes * 30,
              value:
                aplications.length > 0 &&
                aplications.filter(
                  (aplication) => aplication.getHours() === hour && aplication.getMinutes() === minutes * 30
                )[0]
                  ? /* || ((new Date()).getHours()>=17 && weekDay.getDate()===tomorrow.getDate())*/
                    true
                  : false,
              label: hour + ":" + (minutes === 0 ? "00" : minutes * 30),
            };
            // console.log(time);
            times.push(time);
          }
        }
        if (groupAplications.length > 0) {
          groupAplications.forEach((ap) => {
            let temp = new Date(ap.TestDateTime);
            let scalar = Math.ceil(ap.NumberOfPatients / 4);
            temp.setHours(temp.getHours() - 9);
            temp = temp.getHours() + ":" + (temp.getMinutes() === 0 ? "00" : temp.getMinutes());
            let start = times.map((el) => el.label).indexOf(temp);
            for (let i = 1; i < scalar; i++) {
              times[i + start].value = true;
            }
          });
        }
        return {
          date: weekDay.getDate(),
          times: times,
        };
      });
      dispatch({ type: FETCH_THIS_WEEK_APLICATIONS, payload });
    });
  };
  const sendStartMail = async (email, facility, coupon) => {
    axios.post(server + "/email/startemail", { email, facility, coupon }, myHeaders).then((res) => {});
  };
  const reserveTime = (time, facility, id) => {
    axios.post(server + "/aplication/reservetime", { time, facility }, myHeaders).then((res) => {
      if (res.data.error) {
        let payload = time;
        dispatch({ type: SET_RESERVE_ERROR_LIST, payload });
      } else {
        if (id) {
          axios.post(server + "/changeTime", { id, time }, myHeaders).then((res) => {
            navigate("/aplicationlist/aplication/");
            navigate("/aplicationlist/aplication/" + id);
          });
        } else {
          let payload = time;
          dispatch({ type: SET_TEST_DATE_TIME, payload });
          payload = facility;
          dispatch({ type: SET_FACILITY, payload });
        }
      }
    });
  };
  const setInitialState = async (facility, date) => {
    let payload = { facility, date };
    dispatch({ type: SET_INITIAL_STATE, payload });
  };
  const resetTestDateTime = () => {
    let payload = null;
    dispatch({ type: SET_TEST_DATE_TIME, payload });
    dispatch({ type: RESET_RESERVE_ERROR_LIST });
  };
  const fetchThisWeekNumberOfPatients = async (day) => {
    axios.get(server + "/patient/thisWeekNumberOfPatients/" + day, myHeaders).then((res) => {
      let payload = res.data;
      dispatch({ type: FETCH_THIS_WEEK_NUMBER_OF_PATIENS, payload });
    });
  };
  const sendFeedBack = async (form) => {
    axios.post(server + "/email/feedback", { form }, myHeaders).then((res) => {
      navigate("/feedback/thankyou");
    });
  };
  const fetchCoupon = async (coupon, TypeOfInspection) => {
    axios.post(server + "/coupon/aplication/",{coupon,TypeOfInspection }, myHeaders).then((res) => {
      let payload = res.data? res.data?.length > 100 ? "" : res.data:"";
      dispatch({ type: FETCH_COUPON, payload });
    });
  };
  const fetchHolidays = async () => {
    await axios.get(server + "/holiday/", myHeaders).then((res) => {
      let payload = res.data;
      dispatch({ type: FETCH_HOLIDAYS, payload });
    });
  };
  const clearCoupon = () => {
    dispatch({ type: CLEAR_COUPON, payload: null });
  };
  return (
    <Context.Provider
      value={{
        resetNew,
        submit,
        sendStartMail,
        fetchThisWeekAplications,
        reserveTime,
        setInitialState,
        resetTestDateTime,
        fetchThisWeekNumberOfPatients,
        sendFeedBack,
        fetchCoupon,
        fetchHolidays,
        clearCoupon,
        server: server,
        saveError: state.saveError,
        thisWeekAplications: state.thisWeekAplications,
        testDateTime: state.testDateTime,
        reservedErrorList: state.reservedErrorList,
        facility: state.facility,
        initialState: state.initialState,
        thisWeekNumberOfPatients: state.thisWeekNumberOfPatients,
        coupon: state.coupon,
        holidays: state.holidays,
      }}
    >
      {children}
    </Context.Provider>
  );
};
